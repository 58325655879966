import { FormControl, FormHelperText, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { FormElementData, FormTextFielTypes, FormTextFieldElement } from '../Interfaces'

import { NumericFormat, NumericFormatProps } from 'react-number-format';

import CurrencyInput from 'react-currency-input-field';

interface Props extends FormTextFieldElement{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
    handleSubmitForm?: () => void
}

export default function CurrencyField(props: Props) {
    const data = props.data

    const { id, label } = props

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target
        props.handleOnChange(target.name, target.value)
    }

    return(
        <FormControl fullWidth={props.fullWidth}>
            <div className="loading-form-element-skeleon-box" style={{ border: "none" }}>
            <div className="content-of-loading-form-element-skeleon-box">
                <span className="fake-label" style={{ zIndex: 9 }}>{label}</span>
                <CurrencyInput
                id={id}
                className="currency-field"
                name={id}
                placeholder={label}
                defaultValue={0}
                value={data.value}
                decimalsLimit={2}
                onValueChange={(value, name) => props.handleOnChange(id, value)}
            />
            </div>
        </div>
         
        </FormControl>
    )
}