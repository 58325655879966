import React from 'react'
import { ReportRecordInterface } from "./CheckInApp"
import CheckInApp from "./CheckInApp"
import { DataDisplayerItemButton, DataDisplayerItemField } from '../../DataDisplayer/Interfaces'
import DataSingleDisplayerItem from '../../DataDisplayer/DataSingleDisplayerItem'
import DateAndTime from '../../DateAndTime/DateAndTime'
import { IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import Currencies from '../currencies/Currencies'

interface Props extends ReportRecordInterface {
    index: number
}

export default function SingleRecordBox(props: Props) {

    const checkInApp = new CheckInApp()
    const dateAndTime = new DateAndTime()

    const { index, check_in, check_out, total_amount, total_hours } = props

    const currencies = new Currencies()

    const fields: Array<DataDisplayerItemField> = [
        {
            type: "text",
            label: "Check-in:",
            value: check_in ? dateAndTime.formatDateAndTime(check_in) : "-",
            maxWidth: 300,
            minWidth: 300
        },
        {
            type: "text",
            label: "Check-out:",
            value: check_out ? dateAndTime.formatDateAndTime(check_out) : "-",
            maxWidth: 300,
            minWidth: 300
        },
        {
            type: "text",
            label: "Total Amount:",
            value: String(total_amount) + " " + currencies.currenctCurrency,
            maxWidth: 150,
            minWidth: 150
        },
        {
            type: "text",
            label: "Total Hours:",
            value: String(total_hours),
            maxWidth: 150,
            minWidth: 150
        },
    ]
    const buttons: Array<DataDisplayerItemButton> = []

    return <DataSingleDisplayerItem sectionName="check-in" appClass={checkInApp} id={index} fields={fields} buttons={buttons} />

}