import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import PagePreloader from '../PagePreloader/PagePreloader'
import updatePageTitle from '../Router/components/updatePageTitle'
import { BasePageProps } from '../Router/Interfaces'
import ErrorPage from './Error/ErrorPage'
import UserAuthorizedPage from './UserAuthorizedPage'

export default function BasePage(props: BasePageProps) {

    const errorPage = useSelector((state: RootState) => state.errorPage.details)

    useEffect(() => {
        updatePageTitle(props.page.title)
    })

    /**
     * Render content
     */
    const renderPage = () => {

        if (props.page.disableBaseStructure === true){
            return(
                <React.Fragment>
                    <PagePreloader />
                    <props.page.component page={props.page} />
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <PagePreloader />
                    <div className="page-wrapper">
    
                        <main className="main-page-content">
                            <div className="wrapper-of-main-page-content">
                                <props.page.component page={props.page} />
                            </div>
                        </main>
                    </div>
                </React.Fragment>
            )
        }
    }

    if (errorPage.show === true){
        return <ErrorPage {...errorPage} />
    }else{
        if (props.page.isUserAuthorizedContent){
            return(
                <UserAuthorizedPage onlyAdmin={props.page.onlyAdmin} accessDeniedMessage={props.page.accessDeniedMessage}>
                    {renderPage()}
                </UserAuthorizedPage>
            )
        }else{
            return renderPage()
        }
    }


}