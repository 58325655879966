import React from 'react'
import TeamsApp from "./TeamsApp"
import { DataDisplayerProps, DataDisplayerSingleSearchParam } from '../../DataDisplayer/Interfaces'
import GenerateIcon from '../../FormElements/GenerateIcon'
import { IconButton } from '@mui/material'
import DataDisplayer from '../../DataDisplayer/DataDisplayer'
import LabourApp from "../labour/LabourApp"

interface Props {
    teamId: number
}

export default function ListOfTeamMembers(props: Props) {

    const { teamId } = props

    const teamsApp = new TeamsApp()
    const labourApp = new LabourApp()

    const loadItems = (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam> ) => teamsApp.listOfTeamMembers(teamId, page, search, disablePagination, searchParams)

    const dataDisplayerProps: DataDisplayerProps = {
        sectionName: "labour",
        defaultMessages:  {
            notFound: "No labour have been assigned to this team",
            sureToDelete: "Are you sure you want to delete this labour from the team?",
            addNewText: "Add new labour"
         },
        appClass: teamsApp,
        enableSearch: false,
        loadItems: loadItems,
        customDataDisplayerFields: [
            {
                type: "thumbnail",
                name: "photo",
                title: ""
            },
            {
                type: "text",
                name: "full_name",
                title: "Name:",
                maxWidth: 200,
                minWidth: 200,
            },
            {
                type: "text",
                name: "designation_name",
                title: "Designation:",
                maxWidth: 200,
                minWidth: 200,
            },
            {
                type: "text",
                name: "unique_id",
                title: "Unique ID:",
                maxWidth: 250,
                minWidth: 250,
            },
        ],
        customDataDisplayerButtons: [
            {
                type: "custom",
                label: "Edit",
                icon: "",
                customContent(details) {
                    return <IconButton href={`/panel/labour/edit/${details.id}?back=/panel/teams/edit/${teamId}`}><GenerateIcon icon="edit" /></IconButton>
                },
            },
            // {
            //     type: "custom",
            //     label: "Delete",
            //     icon: "",
            //     customContent(details) {
            //         return <DeleteLabourButton id={details.id} handleDelete={deleteLabour} />
            //     },
            // }
        ]
    }
    
    return <DataDisplayer {...dataDisplayerProps} />
}