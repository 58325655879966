import { FormSelectSingleItem } from "../../FormElements/Interfaces"

/**
 * Currencies
 */
export default class Currencies {

    currenctCurrency: string = "SAR"

    currencies: Array<FormSelectSingleItem> = [
        {
            id: "SAR",
            title: "SAR",
            subItems: []
        },
        // {
        //     id: "BHD",
        //     title: "BHD",
        //     subItems: []
        // }
    ]

}