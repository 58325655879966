import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../app/store";
import DateAndTime from "../../DateAndTime/DateAndTime";
import ApiEndpoint from "../ApiEndpoint";
import moment from "moment"
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";

export type userTypes = "admin" | "superuser" | "staff" | "guest"

/**
 * User
 */
export default class User extends ApiEndpoint{

    appName = "users"
    appBaseEndPointPath = "users"

    /**
     * Verify access token
     */
    verifyAccessToken = () => this.sendRequest({
        url: `${this.appBaseEndPointPath}/token/verify`,
        method: "POST",
        withCredentials: true
    })

    /**
     * Refresh access token
     */
    refreshAccessToken = () => this.sendRequest({
        url: `${this.appBaseEndPointPath}/token/refresh`,
        method: "POST",
        withCredentials: true
    })

    /**
     * Is user logged in
     */
    isLoggedIn = async() => {

        /**
         * Return response
         */
        const returnResponse = (isLoggedIn: boolean, message: string) => {
            return {
                isLoggedIn,
                message
            }
        }

        /**
         * Return something went wrong message
         */
        const returnSomethingWentWrongMessage = (status: number, statusText: string) => {
            return returnResponse(false, "Check the log, seomthing went wrong. Status Text: " + statusText + "; Status: " + status)
        }

        /**
         * Return success message
         */
        const returnSuccessMessage = () => {
            return returnResponse(true, "Yes, user logged in!")
        }

        /**
         * Verify the token
         */
        return this.verifyAccessToken()
            .then(response => {

                const { status, statusText } = response

                switch(status){

                    case 200:
                        return returnSuccessMessage()
                        break

                    default:
                        return returnSomethingWentWrongMessage(status, statusText)
                }

            })
            .catch(error => {
                const response = error.response 

                switch(response.status){

                    case 401:
                        return this.refreshAccessToken()
                            .then(response => {
                                const { status, statusText } = response

                                switch(status){
                
                                    case 200:
                                        this.updateTokenRefeshedDate(true)
                                        return returnSuccessMessage()
                                        break
                
                                    default:
                                        this.updateTokenRefeshedDate(false)
                                        return returnSomethingWentWrongMessage(status, statusText)
                                }
                            })
                            .catch(error => {
                                this.logResults(error)
                                this.updateTokenRefeshedDate(false)
                                return returnResponse(false, "Please, re-sign into your account")
                            })
                        break;

                    default:
                        this.updateTokenRefeshedDate(false)
                        return returnResponse(false, "Please, re-sign into your account")
                }

            })
    }

    /**
     * Get current user details
     */
    getCurrentUserDetails = () => this.sendRequest({
        url: `${this.appBaseEndPointPath}/current-user`,
        method: "GET",
        withCredentials: true
    })

    /**
     * Get current user details
     */
    getCurrentUserLoginSessionsList = (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam>) => {

        let path: string = `${this.appBaseEndPointPath}/current-user/login-sessions`

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }

        if (searchParams && searchParams.length > 0){
            searchParams.map((searchParam: DataDisplayerSingleSearchParam) => {
                path += `&${searchParam.name}=${searchParam.value}`
            })
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        return this.sendRequest({
            url: path,
            method: "GET",
            withCredentials: true
        })
    }

    /**
     * Get token refreshed
     */
    getTokenRefreshed = () => window.localStorage.getItem("token_refreshed")

    /**
     * Update token refreshed
     */
    updateTokenRefreshed = (value: string) => {
        window.localStorage.setItem("token_refreshed", value)
    }

    /**
     * Update token refreshed date
     */
    updateTokenRefeshedDate = (update: boolean) => {
        const dateAndTime = new DateAndTime()
        let date = ""

        if (update){
            date = dateAndTime.formatToServerStandart(dateAndTime.getCurrentDate())
        }

        this.updateTokenRefreshed(date)

    }

    /**
     * Validate token refreshed
     */
    validateTokenRefresh = () => {
        const tokenRefreshed = this.getTokenRefreshed()

        if (tokenRefreshed !== null){

            const tokenRefreshedDate = new Date(moment(tokenRefreshed).toString())
            const tokenWillExpire = moment(tokenRefreshedDate, "YYYY-MM-DD HH:mm:ss").add(4, 'minutes').toDate()
            const currentDate = new Date()

            if (currentDate < tokenWillExpire){
                return true
            }

        }

        return false
    }

    /**
     * Return user type title
     */
    returnUserTypeTitle = (type: userTypes) => {

        let name: string = ""

        switch(type){

            case "admin":
                name = "Admin"
            break 

            case "superuser":
                name = "Super-user"
            break

            case "guest":
                name = "Guest"
            break

            case "staff":
                name = "Staff"
            break

            case "guest":
                name = "Guest"
            break

        }

        return name 

    }

    /**
     * Update user details
     */
    updateCurrentUserDetails = (first_name: string, last_name: string, email: string, thumbnail: string, middle_name: string, phone: string) => this.sendRequest({
        url: `${this.appBaseEndPointPath}/current-user`,
        method: "POST",
        data: {
            first_name,
            last_name,
            email,
            thumbnail,
            middle_name,
            phone
        },
        withCredentials: true
    })

    /**
     * Update user password
     */
    updateCurrentUserPassword = (password: string, otp: string) => this.sendRequest({
        url: `${this.appBaseEndPointPath}/current-user/update-password`,
        method: "POST",
        data: {
            password,
            otp
        },
        withCredentials: true
    })

    /**
     * 
     */

}