import React from 'react'
import { DataDisplayerProps } from '../../DataDisplayer/Interfaces'
import LocationsApp from './LocationsApp'
import DataDisplayer from '../../DataDisplayer/DataDisplayer'
import ProjectsApp from '../projects/ProjectsApp'
import AdminPanel from '../../AdminPanel/AdminPanel'
import { IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'

interface Props {
    projectId: number
}

export default function LocationsList(props: Props) {

    const projectsApp = new ProjectsApp()

    const adminPanel = new AdminPanel()
    const locationsSection = adminPanel.returnSingleAdminSectionDetails("locations")


    const dataDisplayerProps: DataDisplayerProps = {
        sectionName: "locations",
        defaultMessages:  {
            notFound: "No locations have been found",
            sureToDelete: "Are you sure you want to delete this location?",
            addNewText: "Add new location"
         },
        appClass: new LocationsApp(),
        enableSearch: false,
        // customLoadItemsFunction?: (page: number, search: string | null, disablePagination: boolean, searchParams: Array<DataDisplayerSingleSearchParam>, customDataDisplayerButtons?: Array<DataDisplayerItemButton>, customDataDisplayerFields?: Array<DataDisplayerSectionField>) => void,
        loadItems: (page, search, disablePagination, searchParams) => projectsApp.locationsList(props.projectId, page, search, disablePagination, searchParams),
        customDataDisplayerFields: locationsSection.dataDisplayerFields,
        customDataDisplayerButtons: [
            {
                type: "custom",
                label: "Edit",
                icon: "",
                customContent(details) {
                    return <IconButton href={`/panel/locations/edit/${details.id}?back=/panel/projects/view/${props.projectId}`}><GenerateIcon icon="edit" /></IconButton>
                },
            }
        ]
    }

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <div className="title-box-of-admin-panel-single-section-container">
                    <div className="content-of-title-box-of-admin-panel-single-section-container">
                        <h3>Locations</h3>
                    </div>
                </div>

                <div>
                    <DataDisplayer {...dataDisplayerProps} />
                </div>

            </div>
        </div>
    )
}