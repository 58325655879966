import React from 'react'
import { ReportInterface } from './CheckInApp'
import DateAndTime from '../../DateAndTime/DateAndTime'
import ReportInfoBox from "./ReportInfoBox"
import { ReportInfoBoxInterface } from "./ReportInfoBox"
import Currencies from '../currencies/Currencies'
import { useReturnCurrentUser } from '../../Api/User/userSlice'

interface Props extends ReportInterface {
    loading?: boolean
}

export default function ReportTotal(props: Props) {

    const { total_amount, total_hours, loading } = props

    const dateAndTime = new DateAndTime()

    const currencies = new Currencies()

    const currentUser = useReturnCurrentUser()

    const details: Array<ReportInfoBoxInterface> = [
        {
            title: "Total Amount:",
            value: total_amount + " " + currencies.currenctCurrency,
            loading
        },
        {
            title: "Total Hours:",
            value: total_hours,
            loading
        },
        {
            title: "Report Created:",
            value: dateAndTime.formatDateAndTime(dateAndTime.getCurrentDate()),
            loading
        },
        {
            title: "Created by:",
            value: currentUser.first_name + " " + currentUser.last_name,
            loading
        },
    ]

    return(
        <div className="report-summary-box">
            <h3>Report Summary</h3>

            <div className="check-in-report-info-box-container">
                <div className="content-of-check-in-report-info-box-container">
                    {details.map((detail: ReportInfoBoxInterface, index: number) => <ReportInfoBox key={index} {...detail} />)}
                </div>
            </div>
        </div>
    )
}