import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import RegistrationCountersApp, { RegistrationCounterInterface } from "./RegistrationCountersApp"
import { ItemDetailsSingleBoxProps } from "../labour/LabourApp"
import DateAndTime from "../../DateAndTime/DateAndTime"
import ItemDetailsSingleBox from "../labour/ItemDetailsSingleBox"
import { Grid, IconButton, Skeleton } from '@mui/material'
import ErrorPage from '../../Pages/Error/ErrorPage'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import GenerateIcon from '../../FormElements/GenerateIcon'

export default function SingleRegistrationCounterPage() {

    const params = useParams()
    const id: number = params.id ? Number(params.id) : 0

    const registrationCountersApp = new RegistrationCountersApp()

    const [loading, setLoading] = useState<boolean>(true)
    const [counter, setCounter] = useState<RegistrationCounterInterface>({
        id: 0,
        title: "",
        description: "",
        thumbnail: "",
        project_id: 0,
        location_id: 0,
        token: "",
        token_expires: "",
        is_public: false,
        is_active: false,
        user_id: 0,
        date_created: "",
        last_updated: "",
        project_name: "",
        location_name: "",
        user_name: ""
    })

    const [errorMessage, setErrorMessage] = useState<string>("")

    const [token, setToken] = useState<string>("")
    const [tokenExpiryDate, setTokenExpiryDate] = useState<string>("")
    const [loadingNewToken, setLoadingNewToken] = useState<boolean>(false)

    /**
     * Load counter
     */
    const loadCounterDetails = () => {
        setLoading(true)
        registrationCountersApp.getSingleInstanceDetails(id)
            .then(response => {

                const { status, data } = response

                switch(status){

                    case 200:
                        if (data){
                            const counterDetails: RegistrationCounterInterface = data
                            setCounter(counterDetails)
                            setToken(counterDetails.token)
                            setTokenExpiryDate(counterDetails.token_expires)
                        }
                        break;

                }

                setLoading(false)
            })
            .catch(error => {
                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)

                setLoading(false)
            })
    }
    
    /**
     * Load counter details
     */
    useEffect(() => {
        loadCounterDetails()
    }, [])

    /**
     * Refresh Token
     */
    const refreshToken = () => {
        setLoadingNewToken(true)
        registrationCountersApp.refreshToken(token)
            .then(response => {

                const { status, data } = response

                switch(status){

                    case 200:
                        if (data){
                            setToken(data.token)
                            setTokenExpiryDate(data.token_expires)
                        }
                        break;

                }

                setLoadingNewToken(false)
            })
            .catch(error => {
                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)

                setLoadingNewToken(false)
            })
    }

    /**
     * Items
     */
    const itemDetailsArray: Array<ItemDetailsSingleBoxProps> = [
        {
            title: "Title:",
            value: counter.title,
            xs: 12
        },
        {
            title: "Description:",
            value: counter.description ? counter.description : "-",
            xs: 12
        },
        {
            title: "Project:",
            value: counter.project_name ? counter.project_name : "-",
            xs: 6
        },
        {
            title: "Location:",
            value: counter.location_name ? counter.location_name : "-",
            xs: 6
        },
        {
            title: "Token:",
            value: "",
            xs: 6,
            customContent: () => {

                if (loadingNewToken){
                    return <h3><Skeleton variant="text" width={100} height={20} /></h3>
                }

                return <h3>
                    {token}
                    <IconButton style={{ marginLeft: 5 }} onClick={() => refreshToken()}>
                        <GenerateIcon icon="refresh" />
                    </IconButton>
                    </h3>
            }
        },
        {
            title: "Token expires:",
            value: "",
            xs: 6,
            customContent: () => {
                if (loadingNewToken){
                    return <h3><Skeleton variant="text" width={100} height={20} /></h3>
                }

                return <h3>{tokenExpiryDate ? new DateAndTime().formatDateAndTime(tokenExpiryDate) : "-"}</h3>
            },
        },
        {
            title: "Created by:",
            value: counter.user_name,
            xs: 12
        },
        {
            title: "Date created:",
            value: counter.date_created ? new DateAndTime().formatDateAndTime(counter.date_created) : "-",
            xs: 6
        },
        {
            title: "Last Updated:",
            value: counter.last_updated ? new DateAndTime().formatDateAndTime(counter.last_updated) : "-",
            xs: 6
        },
    ]

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

               {errorMessage ? <GenerateMessageBox type="error" title="Error" message={errorMessage} /> :(
                 <div className="reg-counter-fileds-container-info-box">
                    <div className="content-of-reg-counter-fileds-container-info-box">
                        <Grid container spacing={2}>
                            {itemDetailsArray.map((singleItemDetailsBox: ItemDetailsSingleBoxProps, index: number) => <ItemDetailsSingleBox loading={loading} useNewDesign={true} {...singleItemDetailsBox} key={index} />)}
                        </Grid>
                    </div>
                </div>
               )}

            </div>
        </div>
    )
}