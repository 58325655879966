import React, { useEffect, useState } from 'react'
import RegistrationCountersApp from './RegistrationCountersApp'
import ErrorPage from '../../Pages/Error/ErrorPage'

import { Alert, Button, CircularProgress, Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import GenerateIcon from '../../FormElements/GenerateIcon'
import DateAndTime from '../../DateAndTime/DateAndTime'
import FaceDetector from '../labour/FaceDetector'
import { LabourInterface } from '../labour/LabourApp'
import LabourCard from '../labour/LabourCard'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'

export default function SingleCounterPage() {

    const registrationCounterApp = new RegistrationCountersApp()

    const dateAndTime = new DateAndTime()

    const [loading, setLoading] = useState<boolean>(true)

    const [registrationType, setRegistrationType] = useState<string>("")

    const [showTokenForm, setShowTokenForm] = useState<boolean>(false)
    const [token, setToken] = useState<string>("")
    const [tokenSetDate, setTokenSetDate] = useState<string>("")

    const [createTokenLoading, setCreateTokenLoading] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>("")

    const [errorMessage, setErrorMessage] = useState<string>("")

    const [faceDetectedPhoto, setFaceDetectedPhoto] = useState<string>("")


    const emptyLabour: LabourInterface = {
        id: 0,
        unique_id: "",
        passport_number: "",
        passport_img: "",
        full_name: "",
        designation_id: 0,
        rate: 0,
        currency: "",
        phone: "",
        is_active: false,
        user_id: 0,
        date_created: "",
        last_updated: "",
        photo: "",
        designation_name: "",
        user_name: "",
        team_name: ""
    }
    const [labour, setLabour] = useState<LabourInterface>(emptyLabour)

    interface RecordMessageInterface {
        show: boolean,
        title: string,
        message: string,
        type: MessageBoxTypes
    }
    const [newRecordMessage, setNewRecordMessage] = useState<RecordMessageInterface>({
        show: false,
        title: "",
        message: "",
        type: "info"
    })

    /**
     * Return current date and time
     */
    const returnCurrentDateAndTime = () => dateAndTime.formatToServerStandart(dateAndTime.getCurrentDate())

    /**
     * Validate token
     */
    const validateToken = () => {
        setLoading(true)
        registrationCounterApp.verifyToken()
            .then(response => {

                const data = response.data 
                const token = data.token

                if (token){
                    setToken(token)
                    setErrorMessage("")

                    if (data.token_expires){
                        setTokenSetDate(data.token_expires)
                    }
                }
                setLoading(false)

            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                
                switch(response.status){

                    case 400:
                        setShowTokenForm(true)
                        break

                    case 401:
                        refreshToken()
                        break;

                    case 404:
                        setShowTokenForm(true)
                        break;

                    default:
                        setErrorMessage(message)
                }

                setLoading(false)

            })
    }

    /**
     * Refresh token
     */
    const refreshToken = () => {
        setLoading(true)
        registrationCounterApp.refreshToken()
            .then(response => {

                const data = response.data 
                const token = data.token

                if (token){
                    setToken(token)
                    setTokenSetDate(returnCurrentDateAndTime())
                    setErrorMessage("")

                    if (data.token_expires){
                        setTokenSetDate(data.token_expires)
                    }
                }
                setLoading(false)

            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                
                switch(response.status){

                    case 400:
                        setShowTokenForm(true)
                        break

                    case 404:
                        setShowTokenForm(true)
                        break

                    case 401:
                        refreshToken()
                        break;

                    default:
                        setErrorMessage(message)
                }

                setLoading(false)

            })
    }

    /**
     * Set token
     */
    const handleSetToken = () => {
        setCreateTokenLoading(true)
        registrationCounterApp.setToken(token)
            .then(response => {

                const data = response.data

                setCreateTokenLoading(false)
                setShowTokenForm(false)
                setErrorMessage("")

                if (data.token){
                    setToken(data.token)
                }

                if (data.token_expires){
                    setTokenSetDate(data.token_expires)
                }

            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                switch(response.status){
                    default:
                        setAlertMessage(message)
                }
                setCreateTokenLoading(false)
            })
    }

    /**
     * Reset Views
     */
    const resetViews = () => {
        setShowTokenForm(false)
        setRegistrationType("")
        setErrorMessage("")
        setAlertMessage("")
        setLabour(emptyLabour)
        setFaceDetectedPhoto("")
    }

    /**
     * Reset new record message
     */
    const resetNewRecordMessage = () => setNewRecordMessage({
        show: false,
        title: "",
        message: "",
        type: "info"
    })

    /**
     * Create new record
     */
    const createNewRecord = () => {
        setLoading(true)
        registrationCounterApp.createNewRecord(registrationType, labour.unique_id, faceDetectedPhoto)
            .then(response => {

                const data = response.data
                const message = data.message ? data.message : "Reecord has been created!"

                setNewRecordMessage({
                    show: true,
                    title: "Success!",
                    message,
                    type: "success"
                })

                setTimeout(() => {
                    resetViews()
                    resetNewRecordMessage()
                }, 1500)

                setLoading(false)

            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                switch(response.status){
                    default:
                        setNewRecordMessage({
                            show: true,
                            title: "Error!",
                            message,
                            type: "error"
                        })

                        setTimeout(() => {
                            resetViews()
                            resetNewRecordMessage()
                        }, 1500)
                }
                setLoading(false)
            })
    }

    /**
     * Effect
     */
    useEffect(() => {

        validateToken()
        /**
         * Validate token every 5 min
         */
        setInterval(() => {
            validateToken()
        }, 300000)
        
    }, [])

    /**
     * Check if token is expired
     */
    // useEffect(() => {
        
    //     if (tokenSetDate){
    //         setInterval(() => {
    //             if(!registrationCounterApp.validateExpiryDate(tokenSetDate)){
    //                 refreshToken()
    //             }
    //         }, 5000)
    //     }

        
    // }, [tokenSetDate])

    /**
     * Render token form
     */
    const renderTokenForm = () => {
        return(
            <div className="login-form-container" style={{ minWidth: 400 }}>
                <div className="content-of-login-form-container">

                    <div className="two-factor-auth-head-box">
                        <div className="content-of-two-factor-auth-head-box">

                            <Grid container spacing={2}>

                                <Grid item xs={2}>
                                    <div className="icon-box-of-two-factor-auth-head-box">
                                        <div className="content-of-icon-box-of-two-factor-auth-head-box">
                                            <GenerateIcon icon="password" />
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={10}>
                                    <div className="text-box-of-two-factor-auth-head-box">
                                        <div className="content-of-text-box-of-two-factor-auth-head-box">
                                            <h3>Token</h3> 
                                            <p>Paste the registration counter token into the field</p>
                                        </div>
                                    </div>
                                </Grid>

                            </Grid>

                        </div>
                    </div>  

                    <div className="actual-form-of-login-form-container">
                        <div className="two-factor-auth-form">
                            <TextField id="outlined-basic" label="Token" variant="outlined" fullWidth value={token} type="text" onChange={(e) => setToken(e.target.value)} />
                            {alertMessage && <Alert style={{ marginTop: 5 }} severity="error">{alertMessage}</Alert>}
                            <LoadingButton loading={createTokenLoading} variant="contained" fullWidth style={{ marginTop: 10 }} onClick={() => handleSetToken()}>Submit</LoadingButton>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    /**
     * Render registration select box
     */
    const renderRegistrationSelectBox = () => {
        return (
            <div className="select-regitsration-type-select-box">
                <div className="content-of-select-regitsration-type-select-box">

                    <h3>Select registration type:</h3>

                    <div className="buttons-box-of-select-regitsration-type-select-box">
                        <div className="content-of-buttons-box-of-select-regitsration-type-select-box">
                            <Button className="check-in-button" onClick={() => setRegistrationType("in")}>
                                Check-in
                            </Button>

                            <Button className="check-out-button" onClick={() => setRegistrationType("out")}>
                                Check-out
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

     /**
     * Render preloader
     */
    const renderPreloader = () => {
        let preloaderClassName = "face-detector-preloader-container"

        if (loading){
            preloaderClassName = preloaderClassName + " active"
        }

        return(
            <div className={preloaderClassName}>
                <div className="content-of-face-detector-preloader-container">
                    <CircularProgress />
                </div>
            </div>
        )

    }

    /**
     * Handle face detected
     */
    const handleLabourDetected = (labour: LabourInterface, photo: string) => {
        setLabour(labour)
        setFaceDetectedPhoto(photo)
    }

    /**
     * Render Labour Card
     */
    const renderLabourCard = () => {
        return(
            <div className="miraj-id-card-container-of-registration-counter">
                <div className="content-of-miraj-id-card-container-of-registration-counter">
                    <LabourCard {...labour} />

                    <div className="buttons-box-of-miraj-id-card-container-of-registration-counter">
                        <div className="content-of-buttons-box-of-miraj-id-card-container-of-registration-counter">

                            <Button color="success" onClick={() => createNewRecord()}>
                                Yes, it is me
                            </Button>

                            <Button color="error" onClick={() => resetViews()}>
                                No, it is not me
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="single-registration-counter-page">
            <div className="content-of-single-registration-counter-page">

                {renderPreloader()}

                {errorMessage && <ErrorPage title={"Error"} message={errorMessage} status={500} show={true} />}
                {showTokenForm && renderTokenForm()}
                {!showTokenForm && !registrationType && renderRegistrationSelectBox()}
                {registrationType && !labour.unique_id && <FaceDetector handleLabourDetected={handleLabourDetected} resetViews={resetViews} detect_by_project={true} />}
                {newRecordMessage.show ? <GenerateMessageBox {...newRecordMessage} /> : labour.unique_id && registrationType && renderLabourCard()}

            </div>
        </div>
    )
}