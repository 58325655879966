import React, { useEffect, useState } from 'react'
import { LabourInterface } from './LabourApp'
import { Avatar, Grid } from '@mui/material'
import {ReactComponent as MirajLogo} from "../../../assets/svg/miraj-logo-dark.svg"
import QRCode from 'react-qr-code'
import { getEmergencyFoundImg } from "../check_in/ConvertIMGToBase64"
import html2canvas from 'html2canvas';

interface Props extends LabourInterface {

}

export default function LabourCard(props: Props) {

    const { id, unique_id, full_name, passport_number, designation_id, designation_name, photo, phone } = props

    const cardId = "card-" + unique_id

    // const [cardImg, setCardImg] = useState<string>("")

    // /**
    //  * Generate image
    //  */
    // const generateImage = () => {
    //     const elem = document.getElementById(cardId)

    //     if (elem){
    //         html2canvas(elem, {
    //             useCORS: true,
    //             allowTaint : true
    //         }).then(canvas => {
    //             const data = canvas.toDataURL('image/png');
               
    //             setCardImg(data)
    //         });
    //     }
    // }

    // /**
    //  * Generate image
    //  */
    // useEffect(() => {
    //     setTimeout(() => {
    //         generateImage()
    //     }, 2000)
    // }, [])

    return(
        <>
            <div className="wrapper-of-miraj-labour-card-container-box">
                <div className="miraj-labour-card-container-box" id={cardId}>
                    <div className="content-of-miraj-labour-card-container-box">

                        <div className="left-side-of-miraj-labour-card-container-box">
                            <div className="content-of-left-side-of-miraj-labour-card-container-box">

                                <div className="thumbnail-box-of-miraj-labour-card-container-box">
                                    <div className="content-of-thumbnail-box-of-miraj-labour-card-container-box">
                                        <Avatar variant='circular' src={photo} sx={{ width: 150, height: 150 }} />
                                        {/* <img src={getEmergencyFoundImg(photo)} /> */}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="right-side-of-miraj-labour-card-container-box">
                            <div className="content-of-right-side-of-miraj-labour-card-container-box">

                                <div className="header-box-of-miraj-labour-card-container-box">
                                    <div className="content-of-header-box-of-miraj-labour-card-container-box">
                                        <h3>
                                            Miraj ID Card:: <span className='id-number-on-miraj-card'>{unique_id}</span>
                                        </h3>
                                    </div>
                                </div>

                                <div className="text-information-of-miraj-labour-card-container-box">
                                        <div className="content-of-text-information-of-miraj-labour-card-container-box">

                                            <Grid container spacing={2}>

                                                <Grid item xs={6}>
                                                    <div className="labour-single-text-data-box">
                                                        <div className="content-of-labour-single-text-data-box">
                                                            <h4>Name:</h4>
                                                            <h3>{full_name}</h3>
                                                        </div>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <div className="labour-single-text-data-box">
                                                        <div className="content-of-labour-single-text-data-box">
                                                            <h4>ID/Passport Number:</h4>
                                                            <h3>{passport_number}</h3>
                                                        </div>
                                                    </div>
                                                </Grid>


                                                <Grid item xs={6}>
                                                    <div className="labour-single-text-data-box">
                                                        <div className="content-of-labour-single-text-data-box">
                                                            <h4>Designation:</h4>
                                                            <h3>{designation_name}</h3>
                                                        </div>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <div className="labour-single-text-data-box">
                                                        <div className="content-of-labour-single-text-data-box">
                                                            <h4>Phone:</h4>
                                                            <h3>{phone}</h3>
                                                        </div>
                                                    </div>
                                                </Grid>

                                            </Grid>

                                            <div className="bottom-of-miraj-labour-card-container-box">
                                                <div className="content-of-bottom-of-miraj-labour-card-container-box">

                                                    <div className="qr-code-of-miraj-labour-card-container-box">
                                                        <div className="content-of-qr-code-of-miraj-labour-card-container-box">
                                                            <QRCode value={unique_id} size={100}/>
                                                        </div>
                                                    </div>

                                                    <div className="miraj-logo-of-miraj-labour-card-container-box">
                                                        <div className="content-of-miraj-logo-of-miraj-labour-card-container-box">
                                                            {/* <img src={MirajLogo} /> */}
                                                            <MirajLogo />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                            </div>
                        </div>



                    </div>
                </div>
            </div>
            {/* <img src={cardImg} /> */}
        </>
    )
}