import BaseApp from "../BaseApp";
import { LabourInterface } from "./LabourApp"
import { AxiosRequestConfig } from "../../Api/Interfaces";
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import { FormSelectSingleItem } from "../../FormElements/Interfaces";

/**
 * labour Interface 
 */
export interface LabourAssignedToProjectInterface {
    id: number,
    labour_id: number,
    project_id: number,
    location_id: number,
    shift: string,
    user_id: number,
    break_time: number,
    break_type: string,
    date_created: string,
    labour: LabourInterface,
    location_name: string,
    project_name: string
}

/**
 * Companies App
 */
export default class LabourAssignedToProjectApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "labourassigned"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "labour/assigned"

    /**
     * Required fields
     */
    requiredFields = [
        "labour_id",
        "project_id",
        "location_id",
        "shift",
        "break_time",
        "break_type"
    ]

    /**
     * Shifts
     */
    shiftsTypes: Array<FormSelectSingleItem> = [
        {
            id: "day",
            title: "Day Shift",
            subItems: []
        },
        {
            id: "night",
            title: "Night Shift",
            subItems: []
        }
    ]

    /**
     * Break time types
     */
    breakTimeTypes: Array<FormSelectSingleItem> = [
        {
            id: "hours",
            title: "Hours",
            subItems: []
        },
        {
            id: "minutes",
            title: "Minutes",
            subItems: []
        }
    ]

    /**
     * Return shift type title
     */
    returnShiftTypeTitle = (name: string) => {
        let title: string = ""

        if (this.shiftsTypes.length > 0){
            this.shiftsTypes.map(shift => {
                if (shift.id === name){
                    title = shift.title
                }
            })
        }

        return title
    }

    /**
     * Return break time type title
     */
    returnBreakTimeTitle = (name: string) => {
        let title: string = ""

        if (this.breakTimeTypes.length > 0){
            this.breakTimeTypes.map(shift => {
                if (shift.id === name){
                    title = shift.title
                }
            })
        }

        return title
    }

    /**
     * Labour list
     */
    labourAssignedList = async(projectId: number | boolean, location_id: number | boolean, page: number, search: string | null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam>) => {

        let path: string = this.appBaseEndPointPath + "/"

        path += `?page=${page ? page : 1}`

        if (projectId){
            path += `&project_id=${projectId}`
        }

        if (location_id){
            path += `&location_id=${location_id}`
        }

        if (search !== null){
            path += "&search=" + search
        }

        if (searchParams && searchParams.length > 0){
            searchParams.map((searchParam: DataDisplayerSingleSearchParam) => {
                path += `&${searchParam.name}=${searchParam.value}`
            })
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

}