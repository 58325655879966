import BaseApp from "../BaseApp";

/**
 * Designtaion Interface 
 */
export interface DesigntaionInterface {
    id: number,
    title: string,
    is_active: boolean,
    user_id: number,
    date_created: string,
    last_updated: string,
}

/**
 * Designtaions App
 */
export default class DesigntaionsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "designations"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "designations"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
    ]

}