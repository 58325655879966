export default class Api{

    static isInProduction: boolean = true

    /**
     * Return base url
     */
    // static returnBaseUrl = () => "http://192.168.1.51:8003/api/"
    static returnBaseUrl = () => "https://timesheet.stormtimeout.com/api/"

    /**
     * Log response
     */
    static log = (...args: any) => {
        if (!Api.isInProduction){
            console.log(...args)
        }
    }

}