import React from 'react'
import { LabourInterface } from './LabourApp'
import { Button, Grid, Skeleton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import { ItemDetailsSingleBoxProps } from "./LabourApp"
import DateAndTime from "../../DateAndTime/DateAndTime"
import ItemDetailsSingleBox from "./ItemDetailsSingleBox"

interface Props extends LabourInterface {
    loading?: boolean
}

export default function LabourInfoBox(props: Props) {

    const { id, unique_id, passport_number, full_name, designation_name, rate, currency, phone, date_created, last_updated, user_name, photo, team_name } = props    

    /**
     * Items
     */
    const itemDetailsArray: Array<ItemDetailsSingleBoxProps> = [
        {
            title: "Name:",
            value: full_name,
            xs: 12
        },
        {
            title: "Unique ID:",
            value: unique_id,
            xs: 12
        },
        {
            title: "Team:",
            value: team_name ? team_name : "-",
            xs: 12
        },
        {
            title: "Designation:",
            value: designation_name,
            xs: 12
        },
        {
            title: "ID/Passport Number:",
            value: passport_number,
            xs: 12
        },
        {
            title: "Phone:",
            value: phone,
            xs: 12
        },
        {
            title: "Rate:",
            value: `${rate} ${currency} per hour`,
            xs: 12
        },
        {
            title: "Created by:",
            value: user_name,
            xs: 12
        },
        {
            title: "Date created:",
            value: date_created ? new DateAndTime().formatDateAndTime(date_created) : "-",
            xs: 6
        },
        {
            title: "Last Updated:",
            value: last_updated ? new DateAndTime().formatDateAndTime(last_updated) : "-",
            xs: 6
        },
    ]

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <div className="single-item-details-page-boxes-container">
                    <div className="content-of-single-item-details-page-boxes-container">

                        <Grid container spacing={2}>
                            
                            <Grid item xs={2}>
                                <div className="thumb-box-of-single-item-details-page-boxes-container">
                                    <div className="content-of-thumb-box-of-single-item-details-page-boxes-container">
                                        {props.loading ? <Skeleton variant="rectangular" width={"100%"} height={350} /> : <img src={photo} />}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={10}>
                                <div className="field-boxes-of-single-item-details-page-boxes-container">
                                    <div className="content-of-field-boxes-of-single-item-details-page-boxes-container">
                                        <Grid container spacing={2}>
                                        {itemDetailsArray.map((singleItemDetailsBox: ItemDetailsSingleBoxProps, index: number) => <ItemDetailsSingleBox loading={props.loading} useNewDesign={true} {...singleItemDetailsBox} key={index} />)}
                                        </Grid>
                                    </div>
                                </div>

                                <div className="item-details-action-buttons-container">
                                    <div className="content-of-item-details-action-buttons-container">

                                        {props.loading && (
                                            <div style={{ display: "flex" }}>
                                                <Skeleton width={100} height={50} variant="rectangular" style={{ marginRight: 5 }} />
                                            </div>
                                        )}


                                        {!props.loading && (
                                            <Button  startIcon={<GenerateIcon icon="edit" />} href={"/panel/labour/edit/" + id + "?back=/panel/labour/view/" + id}>
                                                Edit
                                            </Button>
                                        )}

                                    </div>
                                </div>
                            </Grid>

                        </Grid>

                    </div>
                </div>

            </div>
        </div>
    )
}