import { FormControl, FormHelperText, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { FormElementData, FormTextFielTypes, FormTextFieldElement } from '../Interfaces'
import LoadingFieldElement from './LoadingFieldElement'
import InputAdornment from '@mui/material/InputAdornment';
import GenerateIcon from '../GenerateIcon';

interface Props extends FormTextFieldElement{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
    handleSubmitForm?: () => void
}

export default function FormElementTextField(props: Props) {
    const data = props.data

    const [fieldType, setFieldType] = useState<FormTextFielTypes>(props.fieldType ? props.fieldType : "text")

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target
        props.handleOnChange(target.name, target.value)
    }

    const handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (props.submitFormOnPressingEnter){
            if (props.handleSubmitForm){
                if (event.key === "Enter"){
                    props.handleSubmitForm()
                }
            }
        }
    }

    let inputProps = {}

    if (props.fieldType === "password"){
        inputProps = {
            endAdornment: <InputAdornment position="end"><IconButton onMouseUp={() => setFieldType("password")} onMouseDown={() => setFieldType("text")}><GenerateIcon icon={fieldType === "password" ? "view" : "hide"} /></IconButton></InputAdornment>
        }
    }

    return(
        <FormControl fullWidth={props.fullWidth}>
            {data.loading ? <LoadingFieldElement label={props.label} /> : <TextField onKeyUp={handleOnKeyUp} error={data.helperTextType === "error" ? true : false} name={props.id} id={props.id} type={fieldType} label={props.label} variant="outlined" fullWidth={props.fullWidth} value={data.value} multiline={props.multiline} maxRows={props.maxRows} minRows={props.minRows} onChange={handleOnChange} disabled={data.disabled} InputProps={inputProps} />}
            {data.displayHelperText && (
                <FormHelperText error={data.helperTextType === "error" ? true : false}>
                    {data.helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
}