import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import BaseApp from "../BaseApp";
import ViewTeamPage from "./ViewTeamPage"

/**
 * Projects App
 */
export default class TeamsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "teams"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "teams"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
    ]

    /**
     * List of team members
     */
    listOfTeamMembers = async(teamId: number, page: number, search: string | null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam>) => {


        let path: string = this.appBaseEndPointPath + `/${teamId}/list`

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }

        if (searchParams && searchParams.length > 0){
            searchParams.map((searchParam: DataDisplayerSingleSearchParam) => {
                path += `&${searchParam.name}=${searchParam.value}`
            })
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        return this.sendRequest({
            method: "GET",
            data: {},
            url: path,
            withCredentials: true
        })
    }

    /**
     * View team page
     */
    viewAdminContentPage = () => {
        return <ViewTeamPage />
    }

}