import { AxiosRequestConfig } from "../../Api/Interfaces";
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import BaseApp from "../BaseApp";
import ViewSingleProjectPage from "./ViewSingleProjectPage"


/**
 * Projects Interface 
 */
export interface ProjetcsInterface {
    id: number,
    title: string,
    description: string | null,
    location_ids: Array<number>,
    is_active: boolean,
    user_id: number,
    date_created: string,
    last_updated: string,
    thumbnail: string,
    user_name: string
}

/**
 * Projects App
 */
export default class ProjectsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "projects"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "projects"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "location_ids"
    ]

    /**
     * View Page
     */
    viewAdminContentPage = () => {
        return <ViewSingleProjectPage />
    }

    /**
     * Locations list
     */
    locationsList = async(projectId: number, page: number, search: string | null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam>) => {

        let path: string = this.appBaseEndPointPath + "/" + projectId + "/locations"

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }

        if (searchParams && searchParams.length > 0){
            searchParams.map((searchParam: DataDisplayerSingleSearchParam) => {
                path += `&${searchParam.name}=${searchParam.value}`
            })
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

}