import { adminPanelRouts } from "../AdminPanel/RegisterAdminRouts";
import HomePage from "../Pages/Home/HomePage";
import NotFoundPage from "../Pages/NotFound/NotFoundPage";
import ProfilePage from "../Pages/Profile/ProfilePage";
import SignInPage from "../Pages/SignIn/SignInPage";
import SetPasswordPage from "../Pages/SetPassword/SetPasswordPage"
import RequestPasswordSetPage from "../Pages/SetPassword/RequestPasswordSetPage"
import LogoutPage from "../Pages/Logout/LogoutPage"
import RedirectToProfile from "../Pages/Profile/RedirectToProfile"
import UnblockAccountPage from "../Pages/SetPassword/UnblockAccountPage"
import BlockAccountPage from "../Pages/Profile/BlockAccountPage"
import FaceDetectorPage from "../apps/labour/FaceDetectorPage"
import SingleCounterPage from "../apps/registration_counters/SingleCounterPage"
import { Pages } from "./Types";

/**
 * Register Pages
 */
let registeredAdminPages: Pages = [
    {
        name: "home-page",
        title: "Home",
        path: "/",
        component: HomePage,
        isUserAuthorizedContent: true
    },
    {
        name: "sign-in-page",
        title: "Sign In",
        path: "/sign-in",
        component: SignInPage,
        isUserAuthorizedContent: false
    },
    {
        name: "profile-page",
        title: "Profile",
        path: "/profile/:section_name",
        component: ProfilePage,
        isUserAuthorizedContent: true
    },
    {
        name: "profile-page-redirect",
        title: "Profile",
        path: "/profile",
        component: RedirectToProfile,
        isUserAuthorizedContent: true
    },
    {
        name: "set-password-page",
        title: "Set Password",
        path: "/set-password/:token",
        component: SetPasswordPage,
        isUserAuthorizedContent: false
    },
    {
        name: "request-set-password-page",
        title: "Request Set Password",
        path: "/set-password",
        component: RequestPasswordSetPage,
        isUserAuthorizedContent: false
    },
    {
        name: "unblock-account",
        title: "Unblock Account",
        path: "/unblock-account",
        component: UnblockAccountPage,
        isUserAuthorizedContent: false
    },
    {
        name: "reset-password-page",
        title: "Reset Password",
        path: "/reset-password/:token",
        component: SetPasswordPage,
        isUserAuthorizedContent: false
    },
    {
        name: "request-reset-password-page",
        title: "Request Reset Password",
        path: "/reset-password",
        component: RequestPasswordSetPage,
        isUserAuthorizedContent: false
    },
    {
        name: "block-account-page",
        title: "Block Account",
        path: "/block-account",
        component: BlockAccountPage,
        isUserAuthorizedContent: false
    },
    {
        name: "single-counter-page",
        title: "Registration Counter",
        path: "/registration-counter",
        component: SingleCounterPage,
        isUserAuthorizedContent: false
    },
    {
        name: "logout",
        title: "Logout",
        path: "/logout",
        component: LogoutPage,
        isUserAuthorizedContent: false
    },
    {
        name: "face-detector",
        title: "Face Detector",
        path: "/face-detector",
        component: FaceDetectorPage,
        isUserAuthorizedContent: true
    },
    {
        name: "page-not-found",
        title: "404",
        path: "*",
        component: NotFoundPage,
        isUserAuthorizedContent: false
    },
    ...adminPanelRouts
]

/**
 * Router Pages
 */
export const routerPages: Pages = registeredAdminPages