import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FolderIcon from '@mui/icons-material/Folder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import StorageIcon from '@mui/icons-material/Storage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GroupIcon from '@mui/icons-material/Group';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import BusinessIcon from '@mui/icons-material/Business';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import GroupsIcon from '@mui/icons-material/Groups';
import DiamondIcon from '@mui/icons-material/Diamond';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import StyleIcon from '@mui/icons-material/Style';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Icon, SvgIcon, makeStyles, Theme } from '@mui/material';
import { ReactComponent as VimeoLogo } from "../../assets/svg/vimeo.svg"
import { ReactComponent as ViberLogo } from "../../assets/svg/viber.svg"
import { ReactComponent as SkypeLogo } from "../../assets/svg/skype.svg"
import { ReactComponent as TiktokLogo } from "../../assets/svg/tiktok.svg"
import { ReactComponent as SnapchatLogo } from "../../assets/svg/snapchat.svg"
import MapIcon from '@mui/icons-material/Map';
import CodeIcon from '@mui/icons-material/Code';
import ContactlessIcon from '@mui/icons-material/Contactless';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SubjectIcon from '@mui/icons-material/Subject';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssessmentIcon from '@mui/icons-material/Assessment';

interface Props {
    icon: string
}
export default function GenerateIcon(props: Props) {

    const { icon } = props

    switch(icon){

        case "dashboard":
            return <HomeIcon />
            break

        case "error":
            return <ErrorOutlineIcon />
            break

        case "warning":
            return <WarningIcon />
            break

        case "warehouse":
            return <WarehouseIcon />
            break

        case "categories":
            return <FolderIcon />
            break

        case "view":
            return <VisibilityIcon />
            break

        case "edit":
            return <EditIcon />
            break 

        case "delete":
            return <DeleteIcon />
            break

        case "info":
            return <InfoIcon />
            break

        case "assignment":
            return <AssignmentIcon />
            break

        case "add":
            return <AddIcon />
            break

        case "back":
            return <ArrowBackIosNewIcon />
            break

        case "forward":
            return <ArrowForwardIosIcon />
            break

        case "refresh":
            return <RefreshIcon />
            break

        case "storage":
            return <StorageIcon />
            break

        case "upload":
            return <CloudUploadIcon />
            break

        case "image":
            return <PhotoSizeSelectActualIcon />
            break

        case "cart":
            return <ShoppingCartIcon />
            break

        case "send":
            return <SendIcon />
            break

        case "close":
            return <CloseIcon />
            break

        case "approve":
            return <DoneAllIcon />
            break

        case "pdf":
            return <PictureAsPdfIcon />
            break

        case "arrow-down":
            return <KeyboardArrowDownIcon />
            break

        case "arrow-up":
            return <KeyboardArrowUpIcon />
            break

        case "success":
            return <DoneAllIcon />
            break

        case "location":
            return <LocationOnIcon />
            break

        case "checkbox-checked":
            return <CheckBoxIcon />
            break

        case "checkbox-blank":
            return <CheckBoxOutlineBlankIcon />
            break

        case "user":
            return <PersonIcon />
            break

        case "company":
            return <BusinessIcon />
            break

        case "designation":
            return <HomeRepairServiceIcon />
            break

        case "users":
            return <GroupIcon />
            break

        case "projects":
            return <FormatListNumberedRtlIcon />
            break

        case "user-groups":
            return <GroupsIcon />
            break

        case "diamond":
            return <DiamondIcon />
            break

        case "shipping":
            return <LocalShippingIcon />
            break

        case "email":
            return <EmailIcon />
            break

        case "password":
            return <LockIcon />
            break

        case "menu":
            return <MenuIcon />
            break

        case "nfc":
            return <StyleIcon />
            break

        case "cards":
            return <RecentActorsIcon />
            break

        case "phone":
            return <LocalPhoneIcon />
            break

        case "website":
            return <LanguageIcon />
            break

        case "instagram":
            return <InstagramIcon />
            break

        case "facebook":
            return <FacebookIcon />
            break

        case "address":
            return <BusinessIcon />
            break

        case "telegram":
            return <TelegramIcon />
            break

        case "link":
            return <InsertLinkIcon />
            break

        case "drag":
            return <DragHandleIcon />
            break

        case "up":
            return <KeyboardArrowUpIcon />
            break

        case "down":
            return <KeyboardArrowDownIcon />
            break

        case "linkedin":
            return <LinkedInIcon />
            break

        case "whatsapp":
            return <WhatsAppIcon />
            break

        case "twitter":
            return <TwitterIcon />
            break

        case "youtube":
            return <YouTubeIcon />
            break

        case "vimeo":
            return (
                <SvgIcon>
                    <VimeoLogo />
                </SvgIcon>
            )

        case "viber":
            return (
                <SvgIcon>
                    <ViberLogo />
                </SvgIcon>
            )

        case "skype":
            return (
                <SvgIcon>
                    <SkypeLogo />
                </SvgIcon>
            )

        case "tiktok":
            return (
                <SvgIcon>
                    <TiktokLogo />
                </SvgIcon>
            )


        case "snapchat":
            return (
                <SvgIcon>
                    <SnapchatLogo />
                </SvgIcon>
            )  
            
        case "googlemaps":
            return <MapIcon />

        case "iframe":
            return <CodeIcon />

        case "nfc":
            return <ContactlessIcon />

        case "folder":
            return <FolderIcon />

        case "folder-open":
            return <FolderOpenIcon />

        case "title":
            return <SubjectIcon />

        case "images":
            return <PermMediaIcon />

        case "contact-box":
            return <ContactMailIcon />

        case "hide":
            return <VisibilityOffIcon />

        case "labour":
            return <FolderSharedIcon />

        case "assignment-id":
            return <AssignmentIndIcon />

        case "registration":
            return <HowToRegIcon />

        case "report":
            return <AssessmentIcon />
            
        default:
            return <HomeIcon />

    }

}