import React from "react"

export interface MirajMenuItemInterface {
    name: string,
    title: string,
    link: string
}

export const menuItems = [
    {
        name: "admin-page",
        title: "Admin Panel",
        link: "/panel/dashboard"
    },
    {
        name: "admin-page-labour",
        title: "Labour",
        link: "/panel/labour"
    },
    {
        name: "admin-page-projects",
        title: "Projects",
        link: "/panel/projects"
    },
    {
        name: "my-profile",
        title: "My Profile",
        link: "/profile/details"
    },
    {
        name: "logout",
        title: "Logout",
        link: "/logout"
    }
]

export default function Menu(){
    return(
        <div className="miraj-warehouse-menu-header">
            <div className="content-of-miraj-warehouse-menu-header">
                {menuItems.map((item: MirajMenuItemInterface, index: number) => (
                    <div className="single-warehouse-menu-item" key={index}>
                        <div className="content-of-single-warehouse-menu-item">
                            <a href={item.link}>{item.title}</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}