import React, { useState } from 'react'
import { CheckInInterface } from "./CheckInApp"
import CheckInApp from "./CheckInApp"
import { DataDisplayerItemButton, DataDisplayerItemField } from '../../DataDisplayer/Interfaces'
import DataSingleDisplayerItem from '../../DataDisplayer/DataSingleDisplayerItem'
import DateAndTime from '../../DateAndTime/DateAndTime'
import { IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import DeleteLabourButton from '../labour/DeleteLabourButton'
import CheckInModalDetails from "./CheckInModalDetails"

interface Props extends CheckInInterface {
    updateCheckInModalUpdateStatus: (status: boolean, id: number) => void,
    refreshData: () => void
}

export default function SingleCheckInBox(props: Props) {

    const { id, labour, labour_id, project_id, location_id, location_name, project_name, user_name, checked_in_by_labour, check_in, check_out, total_hours, total_hours_minus_break, updateCheckInModalUpdateStatus, refreshData, user_id, photo_in, photo_out } = props
    const checkInApp = new CheckInApp()
    const dateAndTime = new DateAndTime()

    const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false)

    /**
     * Handle close detail modal
     */
    const hanldeCloseDetailsModal = () => setDetailsModalOpen(false)

    /**
     * Hanlde open details modal
     */
    const hanldeOpenDetailsModal = () => setDetailsModalOpen(true)

    /**
     * Delete record
     */
    const handleDelete = (idNumber: number) => {
        checkInApp.deleteInstance(id)
            .then(response => {
                    
                const { status } = response

                switch(status){

                    case 200:
                        refreshData()
                        break

                }

            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                alert(message)
            })
    }

    /**
     * Fields
     */
    const fields: Array<DataDisplayerItemField> = [
        {
            type: "thumbnail",
            label: "",
            value: labour.photo
        },
        {
            type: "text",
            label: "Name:",
            value: labour.full_name,
            maxWidth: 300,
            minWidth: 300
        },
        {
            type: "text",
            label: "Check-in:",
            value: check_in ? dateAndTime.formatDateAndTime(check_in) : "-",
            maxWidth: 300,
            minWidth: 300
        },
        {
            type: "text",
            label: "Check-out:",
            value: check_out ? dateAndTime.formatDateAndTime(check_out) : "-",
            maxWidth: 300,
            minWidth: 300
        },
        {
            type: "text",
            label: "Total Hours (without break):",
            value: String(total_hours),
            maxWidth: 200,
            minWidth: 200
        },
        {
            type: "text",
            label: "Total Hours (with break):",
            value: String(total_hours_minus_break),
            maxWidth: 200,
            minWidth: 200
        },
    ]
    const buttons: Array<DataDisplayerItemButton> = [
        {
            type: "custom",
            label: "View",
            icon: "",
            customContent(details) {
                return <IconButton onClick={() => {
                    hanldeOpenDetailsModal()
                }}><GenerateIcon icon="view" /></IconButton>
            },
        },
        {
            type: "custom",
            label: "Edit",
            icon: "",
            customContent(details) {
                return <IconButton onClick={() => {
                    updateCheckInModalUpdateStatus(true, id)
                }}><GenerateIcon icon="edit" /></IconButton>
            },
        },
        {
            type: "custom",
            label: "Delete",
            icon: "",
            customContent: (details) => {
                return <DeleteLabourButton id={details.id} handleDelete={handleDelete}  />
            }
        }
    ]

    const checkIn: CheckInInterface = {
        id,
        labour_id,
        location_id,
        project_id,
        checked_in_by_labour,
        user_id,
        photo_in,
        photo_out,
        labour,
        user_name,
        location_name,
        project_name,
        check_in,
        check_out,
        total_hours,
        total_hours_minus_break,
    }

    return(
        <>
            <CheckInModalDetails open={detailsModalOpen} handleClose={hanldeCloseDetailsModal} checkIn={checkIn} />
            <DataSingleDisplayerItem sectionName="check-in" appClass={checkInApp} id={id} fields={fields} buttons={buttons} />
        </>
    )
}