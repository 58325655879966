import { Button, IconButton, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CheckInModal from './CheckInModal'
import GenerateIcon from '../../FormElements/GenerateIcon'
import CheckInApp, { CheckInInterface } from "./CheckInApp"
import SingleCheckInBox from "./SingleCheckInBox"
import { addManyElementsData, returnSingleFormElementData } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice'
import { useDispatch } from 'react-redux'
import LabourApp from '../labour/LabourApp'
import LocationsApp from '../locations/LocationsApp'
import ProjectsApp from '../projects/ProjectsApp'
import GenerateForm from '../../FormElements/GenerateForm'
import { DataDisplayerPagination, DataDisplayerSingleSearchParam } from '../../DataDisplayer/Interfaces'
import { LoadingButton } from '@mui/lab'
import TeamsApp from '../teams/TeamsApp'
import DateAndTime from '../../DateAndTime/DateAndTime'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'

interface Props {
    labourId?: number,
    projectId?: number,
    locationId?: number,
    teamId?: number,
    startDate?: string,
    finishDate?: string
}

export default function CheckInList(props : Props) {

    const dispatch = useDispatch()

    const { labourId, projectId, locationId, teamId, startDate, finishDate } = props

    const checkInApp = new CheckInApp()
    const locationsApp = new LocationsApp()
    const teamsApp = new TeamsApp()

    const dateAndTime = new DateAndTime()

    const [loading, setLoading] = useState<boolean>(false)
    const [newCheckInOpen, setNewCheckInOpen] = useState<boolean>(false)

    const [items, setItems] = useState<Array<CheckInInterface>>([])
    const [errorMessage, setErrorMessage] = useState<string>("")

    const [updateStatus, setUpdateStatus] = useState<boolean>(false)
    const [updateCheckInId, setUpdateCheckInId] = useState<number>(0)
    const [refreshData, setRefreshData] = useState<boolean>(false)

    const [pagination, setPagination] = useState<DataDisplayerPagination>({
        count: 0,
        current_page: 0,
        total_pages: 0
    })

    /**
     * Search Form
     */
    const prefix: string = "check_in_list_search_form_"
    const formName: string = "check_in_list_search_form"

    /**
     * Get single form element data
     */
    const getSingleFormElementData = (name: string) => returnSingleFormElementData(prefix + name).value

    /**
     * Load locations
     */
    const customLoadLocationsFunc = (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam> ) => locationsApp.locationsList(projectId ? projectId : false, page, search, disablePagination, searchParams)

    /**
     * Create form
     */
    useEffect(() => {
                /**
         * Add new form
         */
                dispatch(addNewForm({
                    name: formName,
                    elements: [
                        {
                            type: "DynamicSelect",
                            xs: 4,
                            id: prefix + "labour_id",
                            label: "Labour",
                            fullWidth: true,
                            idFieldName: "id",
                            titleFieldName: "full_name",
                            appClass: new LabourApp(),
                            addNoneItem: true,
                            formName: formName,
                        },
                        {
                            type: "DynamicSelect",
                            xs: 4,
                            id: prefix + "project_id",
                            label: "Project",
                            fullWidth: true,
                            idFieldName: "id",
                            titleFieldName: "title",
                            appClass: new ProjectsApp(),
                            addNoneItem: true,
                            formName: formName,
                        },
                        {
                            type: "DynamicSelect",
                            xs: 4,
                            id: prefix + "location_id",
                            label: "Location",
                            fullWidth: true,
                            idFieldName: "id",
                            titleFieldName: "title",
                            appClass: new LocationsApp(),
                            addNoneItem: true,
                            formName: formName,
                            customLoadFunc: customLoadLocationsFunc
                        },
                        {
                            type: "DynamicSelect",
                            xs: 3,
                            id: prefix + "team_id",
                            label: "Team",
                            fullWidth: true,
                            idFieldName: "id",
                            titleFieldName: "title",
                            appClass: new TeamsApp(),
                            addNoneItem: true,
                            formName: formName,
                        },
                        {
                            type: "DateAndTimeField",
                            fieldType: "text",
                            xs: 3,
                            id: prefix + "start_date",
                            label: "From",
                            fullWidth: true
                        },
                        {
                            type: "DateAndTimeField",
                            fieldType: "text",
                            xs: 3,
                            id: prefix + "finish_date",
                            label: "To",
                            fullWidth: true
                        },
                        {
                             type: "Button",
                             xs: 3,
                             id: prefix + "submit",
                             label: "Display",
                             fullWidth: true,
                             onClick: () => {},
                             isSubmit: true
                         },
                         {
                            type: "Alert",
                            xs: 12,
                            label: "",
                            id: prefix + "alert"
                        },
                    ]
                }))
        
                 /**
                 * Update form data
                 */
                 dispatch(addManyElementsData([
                    {
                        name: prefix + "labour_id",
                        value: labourId ? labourId : ""
                    },
                    {
                        name: prefix + "location_id",
                        value: locationId ? locationId : ""
                    },
                    {
                        name: prefix + "project_id",
                        value: projectId ? projectId : ""
                    },
                    {
                        name: prefix + "team_id",
                        value: teamId ? teamId : ""
                    },
                    {
                        name: prefix + "start_date",
                        value: startDate ? startDate : ""
                    },
                    {
                        name: prefix + "finish_date",
                        value: finishDate ? finishDate : ""
                    },
                    {
                        name: prefix + "alert",
                        value: "Test",
                        severity: "info",
                        hidden: true
                    },
                    {
                        name: prefix + "submit",
                        value: ""
                    },
                ]))
    }, [])

    /**
     * Load items
     */
    const loadItems = (page: number = 1, loadMore = false) => {
        setLoading(true)

        const getStartDate = getSingleFormElementData("start_date")
        const getFinishDate = getSingleFormElementData("finish_date")

        let data = {
            labour_id: getSingleFormElementData("labour_id"),
            project_id: getSingleFormElementData("project_id"),
            location_id: getSingleFormElementData("location_id"),
            team_id: getSingleFormElementData("team_id"),
            start_date: getStartDate ? dateAndTime.dateJsFormatDateAndTime(getStartDate) : startDate,
            finish_date: getFinishDate ?  dateAndTime.dateJsFormatDateAndTime(getFinishDate) : finishDate
        }

        checkInApp.listOfCheckIns(page, data.labour_id ? data.labour_id : labourId, data.project_id ? data.project_id : projectId, data.location_id ? data.location_id : locationId, "", false, data.team_id ? data.team_id : teamId, data.start_date, data.finish_date)
            .then(response => {
                                
                const { status, statusText, data } = response

                switch(status){

                    case 200:
                        if (data.results && data.results.length > 0){

                            let itemsList = items
                            
                            if (!loadMore){
                                itemsList = data.results
                            }else{
                                data.results.map((singleItem: CheckInInterface) => {
                                    itemsList.push(singleItem)
                                })
                            }

                            setItems(itemsList)

                        }else{
                            setItems([])
                        }

                        setPagination({
                            count: data.count,
                            current_page: page,
                            total_pages: data.total_pages
                        })
                        break

                }

                setLoading(false)
                setRefreshData(false)

            })
            .catch(error => {
                setLoading(false)
                
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                checkInApp.logResults(response)

                switch(response.status){

                    default:
                        setErrorMessage(message)
                }

                setRefreshData(false)
            
            })


    }

    /**
     * Render load more items
     */
    const renderLoadMoreItems = () => {
        if (pagination.current_page == pagination.total_pages){
            return
        }

        return(
            <div className="load-more-items-container">
                <div className="content-of-load-more-items-container">
                    <LoadingButton loading={loading} variant='contained' startIcon={<GenerateIcon icon="refresh" />} onClick={() => loadItems(pagination.current_page + 1, true)}>
                        Load more
                    </LoadingButton>
                </div>
            </div>
        )
    }

    /**
     * Handle open new check in modal
     */
    const handleOpenNewCheckInModa = () => setNewCheckInOpen(true)

    /**
     * Handle close new check in modal
     */
    const handleCloseNewCheckInModa = () => {
        setNewCheckInOpen(false)
        setUpdateStatus(false)
        setUpdateCheckInId(0)
    }

    /**
     * Handle refresh results
     */
    const handleRefreshResults = () => {
        loadItems()
    }

    /**
     * Render items
     */
    const renderItems = () => {

        if (items.length > 0){
            return items.map((item: CheckInInterface, index: number) => <SingleCheckInBox {...item} key={index} updateCheckInModalUpdateStatus={updateCheckInModalUpdateStatus} refreshData={() => setRefreshData(true)} />)
        }

        return <GenerateMessageBox type="info" title="Nothing..." message="Nothing has been found..." />
    }

    /**
     * Effect
     */
    useEffect(() => {
        loadItems()
    }, [])

    /**
     * Update check in form update
     */
    const updateCheckInModalUpdateStatus = (status: boolean, id: number) => {
        setUpdateStatus(status)
        setUpdateCheckInId(id)
        handleOpenNewCheckInModa()
    }

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        loadItems(1)
    }

    /**
     * Refresh data
     */ 
    useEffect(() =>  {
        if (refreshData){
            loadItems(1)
        }
    }, [refreshData])

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <CheckInModal open={newCheckInOpen} handleClose={handleCloseNewCheckInModa} refreshResults={handleRefreshResults} update={updateStatus} checkInId={updateCheckInId} projectId={projectId} locationId={locationId} labourId={labourId} />

                <div className="title-box-of-admin-panel-single-section-container">
                    <div className="content-of-title-box-of-admin-panel-single-section-container">
                        <h3>{loading ? <Skeleton variant="text" width={100} /> : "Check In/Outs"}
                            {!loading && (
                                <IconButton style={{ marginLeft: 5, marginTop: -2 }} onClick={() => handleOpenNewCheckInModa()}>
                                    <GenerateIcon icon="add" />
                                </IconButton>
                            )}
                        </h3>
                    </div>
                </div>

                <div className="check-in-list-search-form-container">
                    <div className="content-of-check-in-list-search-form-container">
                        <GenerateForm name={formName} handleSubmitForm={handleSubmit} />
                    </div>
                </div>

                <div className="check-in-outs-list-container">
                    <div className="content-of-check-in-outs-list-container">
                        {renderItems()}
                        {renderLoadMoreItems()}
                    </div>
                </div>

            </div>
        </div>
    )
}