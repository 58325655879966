import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState, store } from "../../app/store"
import { ApiRequestDataInterface, DataDisplayerItem, SingleDataDisplayerStateItem } from "./Interfaces"

export type DataDisplayerItemsState = Array<SingleDataDisplayerStateItem>
const initialState: DataDisplayerItemsState = []

const dataDisplayerItemsSlice = createSlice({
    name: "dataDisplayerItems",
    initialState,
    reducers: {
        addNewDataDisplayerItems: (state: DataDisplayerItemsState, action: PayloadAction<SingleDataDisplayerStateItem>) => {
            const payload = action.payload
            
            if (state.length > 0){
                // state.map((section: SingleDataDisplayerStateItem) => {
                //     if (section.sectionName !== payload.sectionName){
                //         console.log("payload.sectionName", payload.sectionName)
                //         state.push(payload)
                //     }
                // })
                const findIndex = state.map((x) => x.sectionName ).indexOf(payload.sectionName)

                if (findIndex === 0 || findIndex > 0){
                    if (state[findIndex]){
                        state[findIndex].search = payload.search
                    }
                }else{
                    state.push(payload)
                }
            }else{
                state.push(payload)
            }

        },
        updateDataDisplayerSection: (state: DataDisplayerItemsState, action: PayloadAction<SingleDataDisplayerStateItem>) => {
            const payload = action.payload
            
            if (state.length > 0){
                const findIndex = state.map((x) => x.sectionName ).indexOf(payload.sectionName)

                // state = state.filter(s => s.sectionName !== payload.sectionName)

                if (findIndex === 0 || findIndex > 0){
                    if (state[findIndex]){
                        state[findIndex] = payload
                    }
                }

            }

        },
        updateSearch: (state: DataDisplayerItemsState, action: PayloadAction<{ sectionName: string, search: string | null }>) => {
            const payload = action.payload
            
            if (state.length > 0){
                const findIndex = state.map((x) => x.sectionName ).indexOf(payload.sectionName)

                if (findIndex === 0 || findIndex > 0){
                    if (state[findIndex]){
                        state[findIndex].search = payload.search
                    }
                }

            }
        },
        updateSingleItemLoadingStatus: (state: DataDisplayerItemsState, action: PayloadAction<{ sectionName: string, itemId: number, loading: boolean }>) => {
            const payload = action.payload

            if (state.length > 0){
                const findSectionIndex = state.map((x) => x.sectionName ).indexOf(payload.sectionName)

                if (findSectionIndex === 0 || findSectionIndex > 0){
                    if (state[findSectionIndex]){
                        
                        const section = state[findSectionIndex]
                        const sectionItems = section.items

                        if (sectionItems && sectionItems.length > 0){
                            const findItemIndex = sectionItems.map((i) => i.id).indexOf(payload.itemId)

                            if (findItemIndex === 0 || findItemIndex > 0){
                                if (state[findSectionIndex].items[findItemIndex] !== undefined){
                                    state[findSectionIndex].items[findItemIndex].loading = payload.loading
                                }
                            }

                        }

                    }
                }

            }

        },
        updateSingleItemDetails: (state: DataDisplayerItemsState, action: PayloadAction<{ sectionName: string, itemId: number, details: ApiRequestDataInterface }>) => {
            const payload = action.payload

            if (state.length > 0){
                const findSectionIndex = state.map((x) => x.sectionName ).indexOf(payload.sectionName)

                if (findSectionIndex === 0 || findSectionIndex > 0){
                    if (state[findSectionIndex]){
                        
                        const section = state[findSectionIndex]
                        const sectionItems = section.items

                        if (sectionItems && sectionItems.length > 0){
                            const findItemIndex = sectionItems.map((i) => i.id).indexOf(payload.itemId)

                            if (findItemIndex === 0 || findItemIndex > 0){
                                if (state[findSectionIndex].items[findItemIndex] !== undefined){
                                    state[findSectionIndex].items[findItemIndex].details = payload.details
                                }
                            }

                        }

                    }
                }

            }

        },
        deleteSingleItem: (state: DataDisplayerItemsState, action: PayloadAction<{ sectionName: string, itemId: number }>) => {
            const payload = action.payload

            if (state.length > 0){
                const findSectionIndex = state.map((x) => x.sectionName ).indexOf(payload.sectionName)

                if (findSectionIndex === 0 || findSectionIndex > 0){
                    if (state[findSectionIndex]){
                        
                        const section = state[findSectionIndex]
                        const sectionItems = section.items

                        if (sectionItems && sectionItems.length > 0){
                            const findItemIndex = sectionItems.map((i) => i.id).indexOf(payload.itemId)

                            if (findItemIndex === 0 || findItemIndex > 0){
                                if (state[findSectionIndex].items[findItemIndex] !== undefined){
                                    state[findSectionIndex].items.splice(findItemIndex, 1)
                                }
                            }

                        }

                    }
                }

            }

        },
        updateSingleItemData: (state: DataDisplayerItemsState, action: PayloadAction<{ sectionName: string, itemId: number, itemData: DataDisplayerItem }>) => {
            const payload = action.payload

            if (state.length > 0){
                const findSectionIndex = state.map((x) => x.sectionName ).indexOf(payload.sectionName)

                if (findSectionIndex === 0 || findSectionIndex > 0){
                    if (state[findSectionIndex]){
                        
                        const section = state[findSectionIndex]
                        const sectionItems = section.items

                        if (sectionItems && sectionItems.length > 0){
                            const findItemIndex = sectionItems.map((i) => i.id).indexOf(payload.itemId)

                            if (findItemIndex === 0 || findItemIndex > 0){
                                if (state[findSectionIndex].items[findItemIndex] !== undefined){
                                    state[findSectionIndex].items[findItemIndex] = payload.itemData
                                }
                            }

                        }

                    }
                }

            }

        },
    },
})

export const useReturnSingleDataDisplayerSection = (sectionName: string) => {
    return useSelector((state: RootState) => {

        let defaultState: SingleDataDisplayerStateItem = {
            sectionName: "noname",
            items: [],
            loading: true,
            pagination: {
                count: 1,
                total_pages: 1,
                current_page: 1
            },
            search: ""
        }

        if (state.dataDisplayerItems.length > 0){
            state.dataDisplayerItems.map((item: SingleDataDisplayerStateItem) => {
                if (item.sectionName === sectionName){
                    defaultState =  {
                        sectionName: sectionName,
                        items: item.items,
                        loading: item.loading,
                        pagination: item.pagination,
                        search: item.search
                    }
                }
            })
        }
        
        return defaultState

    })
}

export const returnSingleDataDisplayerSection = (sectionName: string) => {
    const sections = store.getState().dataDisplayerItems

    let defaultState: SingleDataDisplayerStateItem = {
        sectionName: "noname",
        items: [],
        loading: true,
        pagination: {
            count: 1,
            total_pages: 1,
            current_page: 1
        },
        search: ""
    }

    if (sections.length > 0){
        sections.map((item: SingleDataDisplayerStateItem) => {
            if (item.sectionName === sectionName){
                defaultState =  {
                    sectionName: sectionName,
                    items: item.items,
                    loading: item.loading,
                    pagination: item.pagination,
                    search: item.search
                }
            }
        })
    }
    
    return defaultState
}

export const returnSingleDataDisplayerItem = (sectionName: string, itemId: number) => {
    const sections = store.getState().dataDisplayerItems

    let itemDetails: DataDisplayerItem = {
        id: 0,
        fields: [],
        buttons: [],
        details: {}
    }

    if (sections.length > 0){
        sections.map((item: SingleDataDisplayerStateItem) => {
            if (item.sectionName === sectionName){
                if (item.items.length > 0){
                    item.items.map((item: DataDisplayerItem) => {
                        if (item.id === itemId){
                            itemDetails = item
                        }
                    })
                }
            }
        })
    }
    
    return itemDetails
}

export const { addNewDataDisplayerItems, updateDataDisplayerSection, updateSearch, updateSingleItemLoadingStatus, deleteSingleItem, updateSingleItemDetails } = dataDisplayerItemsSlice.actions
export default dataDisplayerItemsSlice.reducer