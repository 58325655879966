import { Button, CircularProgress, IconButton } from '@mui/material'
import React, { useState } from 'react'
import GenerateIcon from '../../FormElements/GenerateIcon'

interface Props {
    id: number,
    handleDelete: (id: number) => void
}

export default function DeleteLabourButton(props: Props) {
    const [sureToDelete, setSureToDelete] = useState<boolean>(false)
    const [loadingDeleteLabour, setLoadingDeleteLabour] = useState<boolean>(false)

    if (loadingDeleteLabour){
        return <IconButton><CircularProgress /></IconButton>
    }

    if (sureToDelete){
        return(
            <div>
                <Button variant="contained" startIcon={<GenerateIcon icon="close" />} className="cancel-to-delete-button" onClick={() => {
                    setSureToDelete(false)
                }}>
                    Cancel
                </Button>
                <Button variant="contained" startIcon={<GenerateIcon icon="success" />} className="sure-to-delete-button" onClick={() => {
                    setLoadingDeleteLabour(true)
                    props.handleDelete(props.id)
                    setTimeout(() => {
                        setLoadingDeleteLabour(false)
                        setSureToDelete(false)
                    }, 1500)
                }}>
                    Confirm?
                </Button>
            </div>
        )
    }

    return <IconButton onClick={() => {
        setSureToDelete(true)
    }}><GenerateIcon icon="delete" /></IconButton>
}