import { AxiosRequestConfig } from "../../Api/Interfaces";
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import BaseApp from "../BaseApp";

/**
 * Location Interface 
 */
export interface LocationInterface {
    id: number,
    title: string,
    address: string,
    google_maps: string | null,
    is_active: boolean,
    user_id: number,
    date_created: string,
    last_updated: string,
}

/**
 * Locations App
 */
export default class LocationsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "locations"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "locations"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "address"
    ]

    locationsList = async(projectId: number | boolean, page: number, search: string | null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam>) => {

        let path: string = this.appBaseEndPointPath + "/"

        path += `?page=${page ? page : 1}`

        if (projectId){
            path += `&project_id=${projectId}`
        }


        if (search !== null){
            path += "&search=" + search
        }

        if (searchParams && searchParams.length > 0){
            searchParams.map((searchParam: DataDisplayerSingleSearchParam) => {
                path += `&${searchParam.name}=${searchParam.value}`
            })
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

}