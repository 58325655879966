import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import { LabourInterface } from './LabourApp'
import LabourApp from "./LabourApp"
import LabourInfoBox from "./LabourInfoBox"
import LabourIDCard from "./LabourIDCard"
import LabourMirajCardBox from "./LabourMirajCardBox"
import CheckInList from "../check_in/CheckInList"

export default function ViewSingleLabourPage() {

    const params = useParams()
    const id: number = params.id ? Number(params.id) : 0

    const labourApp = new LabourApp()
    
    const [loading, setLoading] = useState<boolean>(true)
    const [labour, setLabour] = useState<LabourInterface>({
        id: 0,
        unique_id: "",
        passport_number: "",
        passport_img: "",
        full_name: "",
        designation_id: 0,
        rate: 0,
        currency: "",
        phone: "",
        is_active: false,
        user_id: 0,
        date_created: "",
        last_updated: "",
        photo: "",
        designation_name: "",
        user_name: "",
        team_name: ""
    })

    /**
     * Not Found
     */
    const [errorMessage, setErrorMessage] = useState<string>("")

    /**
     * Load labour details
     */
    const loadLabourDetails = () => {
        setLoading(true)

        labourApp.getSingleInstanceDetails(id)
            .then(response => {
                            
                const { status, statusText, data } = response

                switch(status){

                    case 200:
                        const labour: LabourInterface = data
                        setLabour(labour)
                        break

                }

                setLoading(false)
                setErrorMessage("")

            })
            .catch(error => {
                setLoading(false)
                
                labourApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)
            })

    }

    /**
     * Load labour details
     */
    useEffect(() => {
        loadLabourDetails()
    }, [])

    /**
     * Render not found message
     */
    if (errorMessage){
        return <GenerateMessageBox type="error" title="Nothing was found.." message={errorMessage} />
    }

    /**
     * Render page
     */
    return(
        <div className="single-labour-view-page-container">
            <div className="content-of-single-labour-view-page-container">

                <LabourInfoBox {...labour} loading={loading} />
                <LabourIDCard {...labour} loading={loading} />
                <LabourMirajCardBox {...labour} loading={loading} />
                {!loading && <CheckInList labourId={labour.id} />}

            </div>
        </div>
    )
}