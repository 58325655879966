import React, { useEffect, useState } from 'react'
import ApiEndpoint from "../../Api/ApiEndpoint"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import { Skeleton } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function GeneralStatistics() {

    interface GeneralStatisticsInterface {
        labour: number,
        projects: number,
        locations: number,
        teams: number
    }

    const [loading, setLoading] = useState<boolean>(true)
    const [generalStatistics, setGeneralStatistics] = useState<GeneralStatisticsInterface>({
        labour: 0,
        projects: 0,
        locations: 0,
        teams: 0
    })

    const api = new ApiEndpoint()

    const [errorMessage, setErrorMessage] = useState<string>("")

    /**
     * CHart
     */
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
        //   title: {
        //     display: true,
        //     text: 'Number of:',
        //   },
        },
      };
      
      
   const data = {
        labels: ["Number Of:"],
        datasets: [
          {
            label: 'Labour',
            data: [generalStatistics.labour],
            backgroundColor: 'rgba(109, 169, 228, 0.5)',
          },
          {
            label: 'Projects',
            data: [generalStatistics.projects],
            backgroundColor: 'rgba(246, 186, 111, 0.5)',
          },
          {
            label: 'Locations',
            data: [generalStatistics.locations],
            backgroundColor: 'rgba(11, 36, 71, 0.5)',
          },
          {
            label: 'Teams',
            data: [generalStatistics.teams],
            backgroundColor: 'rgba(87, 108, 188, 0.5)',
          },
        ],
     };

    useEffect(() => {

        api.sendRequest({
            method: "GET",
            url: "statistics/general",
            withCredentials: true,
            data: {}
        })
            .then(response => {
                const { data } = response

                setGeneralStatistics({
                    labour: data.labour ? data.labour : 0,
                    projects: data.projects ? data.projects : 0,
                    locations: data.locations ? data.locations : 0,
                    teams: data.teams ? data.teams : 0
                })

                setLoading(false)
            })
            .catch(error => {
                const { status, data } = error.response
                const message = data.message ? data.message : status

                setErrorMessage(message)
            })

    }, [])
    
    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <div className="title-box-of-admin-panel-single-section-container">
                    <div className="content-of-title-box-of-admin-panel-single-section-container">
                        <h3>General Statistics</h3>
                    </div>
                </div>

                <div className="chart-box-general-statics">
                    {loading ? (
                        <Skeleton variant='rectangular' height={450} />
                    ): (
                        <Bar options={options} data={data} />
                    )}
                </div>

                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

            </div>
        </div>
    )
}