import { FormControl, FormHelperText, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormElementData, FormTextFielTypes, BaseFormElement, CustomFormElementProps } from '../../FormElements/Interfaces'
import LocationsApp from "./LocationsApp"
import SingleLocationField from "./SingleLocationField"
import GenerateMessageBox from "../../FormElements/GenerateMessageBox"
import GenerateIcon from '../../FormElements/GenerateIcon'
import SelectLocationModal from "./SelectLocationModal"

interface Props extends CustomFormElementProps {

}

export default function SelectLocationsField(props: Props) {

    const { id, data, label, handleOnChange } = props

    const [locations, setLocations] = useState<Array<number>>([])
    
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    /**
     * Open modal
     */
    const handleOpenModal = () => setModalOpen(true)

    /**
     * Close modal
     */
    const handleCloseModal = () => setModalOpen(false)

    /**
     * Handle add new location
     */
    const handleAddNewLocation = (id: number) => {
        let locationsList = [
            ...locations
        ]

        if (locations.includes(id)){
            return
        }
        
        locationsList.push(Number(id))

        setLocations(locationsList)
    }

    /**
     * Handle delete location
     */
    const deleteLocation = (id: number) => {
        const index = locations.indexOf(id)
        let locationsArray = [
            ...locations
        ]

        console.log("id", id)
        console.log("index", index)

        if (index > -1){
            locationsArray.splice(index, 1)
        }

        setLocations(locationsArray)

    }

    /**
     * Update locations
     */
    useEffect(() => {
        setLocations(data.value)
    }, [data.value])

    /**
     * Update form element data data
     */
    useEffect(() => {
        handleOnChange(id, locations)
    }, [locations])

    return(
        <div className="select-many-locations-container">
            <div className="content-of-select-many-locations-container">

                <SelectLocationModal open={modalOpen} handleClose={handleCloseModal} addLocation={handleAddNewLocation} />

                <div className="title-box-of-select-many-locations-container">
                    <h3>
                        {label ? label : "Select locations"}
                        <IconButton style={{ marginTop: -2, marginLeft: 5 }} onClick={() => handleOpenModal()}>
                            <GenerateIcon icon="add" />
                        </IconButton>
                    </h3>
                </div>

                <div className="items-box-of-select-many-locations-container">
                    <div className="content-of-items-box-of-select-many-locations-container">
                        {locations.length > 0 ? locations.map((locationId: number) => <SingleLocationField id={locationId} key={locationId} deleteLocation={deleteLocation} />) : <GenerateMessageBox type="info" title="Nothing..." message="No locations have been added" />}
                    </div>
                </div>

            </div>
        </div>
    )
}