import React from 'react'
import { LabourInterface } from './LabourApp'
import { Skeleton } from '@mui/material'

interface Props extends LabourInterface {
    loading?: boolean
}

export default function LabourIDCard(props: Props) {

    const { passport_img, loading } = props

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <div className="title-box-of-admin-panel-single-section-container">
                    <div className="content-of-title-box-of-admin-panel-single-section-container">
                        <h3>{loading ? <Skeleton variant="text" width={100} /> : "ID Card/Passport Photo"}</h3>
                    </div>
                </div>

                <div className="passport-or-id-labour-photo-container">
                    <div className="content-of-passport-or-id-labour-photo-container">
                        {loading ? <Skeleton variant="rectangular" width="100%" height={450} /> : <img src={passport_img} />}
                    </div>
                </div>


            </div>
        </div>
    )
}