import { Grid, IconButton } from "@mui/material"
import React, { useState } from "react"
import MirajLogo from "../../../assets/svg/miraj-logo-dark.svg"
import GenerateIcon from "../../FormElements/GenerateIcon"
import Menu from "./Menu"

export default function Header(){

    const renderLogo = () => <img src={MirajLogo} style={{ cursor: "pointer" }} onClick={() => window.location.replace("/")} />
    const [mobileMenu, setMobileMenu] = useState<boolean>(false)

    let mobileMenuClassName: string = "mobile-sidebar-container"

    if (mobileMenu){
        mobileMenuClassName += " active"
    }
    
    return(
        <header className="miraj-warehouse-header">
            <div className="content-of-miraj-warehouse-header">

                <Grid container spacing={2}>

                    <Grid item xs={2}>
                        <div className="logo-box-of-miraj-warehouse-header">
                            <div className="content-of-logo-box-of-miraj-warehouse-header">
                                {renderLogo()}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="menu-box-of-miraj-warehouse-header">
                            <Menu />
                        </div>

                        <div className="container-of-show-mobile-menu">
                            <div className="show-mobile-menu">
                                <div className="content-of-show-mobile-menu">
                                    <IconButton onClick={() => setMobileMenu(true)}>
                                        <GenerateIcon icon="menu" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Grid>

                </Grid>

                <div className={mobileMenuClassName}>
                    <div className="content-of-mobile-sidebar-container">

                        <div className="mobile-header-container">
                            <div className="content-of-mobile-header-container">

                                <div className="logo-box-of-mobile-sidebar-container">
                                    <div className="content-of-logo-box-of-mobile-sidebar-container">
                                        {renderLogo()}
                                    </div>
                                </div>

                                <div className="show-mobile-menu">
                                    <div className="content-of-show-mobile-menu">
                                        <IconButton onClick={() => setMobileMenu(false)}>
                                            <GenerateIcon icon="close" />
                                        </IconButton>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="menu-of-mobile-sidebar-container">
                            <Menu />
                        </div>

                    </div>
                </div>
                <div className={`mask-of-mobile-sidebar-container ${mobileMenu ? "active" : ""}`}></div>

            </div>
        </header>
    )
}