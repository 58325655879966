import React from 'react'
import { CheckInInterface } from "./CheckInApp"
import ItemDetailsSingleBox from "../labour/ItemDetailsSingleBox"
import { ItemDetailsSingleBoxProps } from "../labour/LabourApp"
import { Grid } from '@mui/material'
import DateAndTime from '../../DateAndTime/DateAndTime'

interface Props extends CheckInInterface {

}

export default function SingleCheckInDetails(props: Props) {

    const { labour, check_in, check_out, checked_in_by_labour, user_name, total_hours, total_hours_minus_break, photo_in, photo_out } = props

    const dateAndTime = new DateAndTime()

    const itemDetailsArray: Array<ItemDetailsSingleBoxProps> = [
        {
            title: "Labour:",
            value: labour.full_name,
            xs: 6
        },
        {
            title: "Unique ID:",
            value: labour.unique_id,
            xs: 6
        },
        {
            title: "Check in:",
            value: check_in ? dateAndTime.formatDateAndTime(check_in) : "-",
            xs: 6
        },
        {
            title: "Check out:",
            value: check_out ? dateAndTime.formatDateAndTime(check_out) : "-",
            xs: 6
        },
        {
            title: "Check in/out by:",
            value: checked_in_by_labour ? "By the labour at the Registration Counter" : user_name,
            xs: 12
        },
        {
            title: "Total Hours:",
            value: `${total_hours}`,
            xs: 6
        },
        {
            title: "Total Hours (minus break time):",
            value: `${total_hours_minus_break}`,
            xs: 6
        },
    ]

    return(
        <div className="single-check-in-details-container">
            <div className="content-of-single-check-in-details-container">

                <div className="info-boxes-of-single-check-in-details-container">
                    <Grid container spacing={2}>
                        {itemDetailsArray.map((singleItemDetailsBox: ItemDetailsSingleBoxProps, index: number) => <ItemDetailsSingleBox useNewDesign={true} {...singleItemDetailsBox} key={index} />)}
                    </Grid>
                </div>

                {photo_in && (
                    <div className="check-in-photo-box">
                        <div className="content-of-check-in-photo-box">
                            <h3>Check-in Face Detected:</h3>
                            <img src={photo_in} />
                        </div>
                    </div>
                )}

                {photo_out && (
                    <div className="check-in-photo-box">
                        <div className="content-of-check-in-photo-box">
                            <h3>Check-out Face Detected:</h3>
                            <img src={photo_out} />
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}