import { AxiosDefaultDataObject, AxiosRequestConfig } from "../../Api/Interfaces";
import { FormSelectSingleItem } from "../../FormElements/Interfaces";
import BaseApp from "../BaseApp";
import { LabourInterface, emptyLabourInterface } from "../labour/LabourApp";
import { ProjetcsInterface } from "../projects/ProjectsApp";

/**
 * Check-in Interface
 */
export interface CheckInInterface {
    id: number,
    labour_id: number,
    location_id: number,
    project_id: number,
    checked_in_by_labour: boolean,
    user_id: number,
    photo_in: string,
    photo_out: string,
    labour: LabourInterface,
    user_name: string,
    location_name: string,
    project_name: string,
    check_in: string,
    check_out: string,
    total_hours: number,
    total_hours_minus_break: number
}

export const emptyCheckInInterface: CheckInInterface = {
    id: 0,
    labour_id: 0,
    location_id: 0,
    project_id: 0,
    checked_in_by_labour: false,
    user_id: 0,
    photo_in: "",
    photo_out: "",
    user_name: "",
    location_name: "",
    project_name: "",
    check_in: "",
    check_out: "",
    total_hours: 0,
    total_hours_minus_break: 0,
    labour: {
        id: 0,
        unique_id: "",
        passport_number: "",
        passport_img: "",
        full_name: "",
        designation_id: 0,
        rate: 0,
        currency: "",
        phone: "",
        is_active: false,
        user_id: 0,
        date_created: "",
        last_updated: "",
        photo: "",
        designation_name: "",
        user_name: "",
        team_name: ""
    }
}

/**
 * Report Record Interface
 */
export interface ReportRecordInterface {
    check_in: string,
    check_out: string,
    break_time: string,
    total_hours: number,
    rate: string,
    total_amount: number
}

/**
 * Single Labour Report
 */
export interface SingleLabourReport {
    labour: LabourInterface,
    records: Array<ReportRecordInterface>,
    total_hours: number,
    total_amount: number,
    rate: number
}

/**
 * Report Interface
 */
export interface ReportInterface {
    labour: Array<SingleLabourReport>,
    project: ProjetcsInterface,
    is_all_locations: boolean,
    labour_name: string,
    start_date: string,
    finish_date: string,
    location_name: string,
    total_amount: number,
    total_hours: number,
    report_name: string
}

/**
 * Projects App
 */
export default class CheckIn extends BaseApp {

    /**
     * App Name
     */
    appName: string = "check-in"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "check-in"

    /**
     * Required fields
     */
    requiredFields = [
        "date",
        "type",
        "labour_id",
        "location_id",
        "project_id",
    ]

    /**
     * Check in types
     */
    checkInTypes: Array<FormSelectSingleItem> = [
        {
            id: "in",
            title: "IN",
            subItems: []
        },
        {
            id: "out",
            title: "OUT",
            subItems: []
        }
    ]

    /**
     * Return shift type title
     */
    returnTypeTitle = (name: string) => {
        let title: string = ""

        if (this.checkInTypes.length > 0){
            this.checkInTypes.map(shift => {
                if (shift.id === name){
                    title = shift.title
                }
            })
        }

        return title
    }

    /**
     * List of check ins
     */
    listOfCheckIns = async(page: number, labourId: number | undefined, projectId: number | undefined, locationId: number | undefined, search: string | null, disablePagination: boolean = false, team_id: number | boolean = false, start_date: string | boolean = false, finish_date: string | boolean = false) => {

        let path: string = this.appBaseEndPointPath

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }

        if (labourId){
            path += `&labour_id=${labourId}`
        }

        if (projectId){
            path += `&project_id=${projectId}`
        }

        if (locationId){
            path += `&location_id=${locationId}`
        }

        if (team_id){
            path += `&team_id=${team_id}`
        }

        if (start_date && finish_date){
            path += `&start_date=${start_date}&finish_date=${finish_date}`
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

    /**
     * Generate report
     */
    generateReport = (project_id: number | boolean = false, location_id: number | boolean = false, labour_id: number | boolean = false, start_date: string | boolean = false, finish_date: string | boolean = false, team_id: number | boolean = false) => {

        let data: AxiosDefaultDataObject = {
            project_id,
            location_id,
            labour_id,
            start_date,
            finish_date,
            team_id
        }

        return this.sendRequest({
            method: "POST",
            data,
            url: this.appBaseEndPointPath + "/generate-report",
            withCredentials: true
        })

    }

}