import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CheckInInterface } from './CheckInApp';
import SingleCheckInDetails from './SingleCheckInDetails';

interface Props {
    open: boolean,
    handleClose: () => void,
    checkIn: CheckInInterface,
}

export default function CheckInModalDetails(props: Props) {

    const { open, handleClose, checkIn } = props

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
      >
            <DialogTitle id="alert-dialog-title">
                Details
            </DialogTitle>

            <DialogContent>
                <div style={{ marginTop: 5 }}>
                    <SingleCheckInDetails {...checkIn} />
                </div>
            </DialogContent>

            <DialogActions>

                <Button onClick={handleClose}>Close</Button>
            </DialogActions>

        </Dialog>
    )
}