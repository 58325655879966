import React, { useEffect } from 'react'
import * as faceapi from 'face-api.js';
import LaboutApp from './LabourApp';
import { CircularProgress } from '@mui/material';
import ErrorPage from '../../Pages/Error/ErrorPage';
import LabourCard from "./LabourCard"

export default function FaceDetector(props) {

    const labourApp = new LaboutApp()
    const [modelsLoaded, setModelsLoaded] = React.useState(false);
    const [captureVideo, setCaptureVideo] = React.useState(false);
    const [faceDetected, setFaceDetected] = React.useState(false)
    const [userPhoto, setUserPhoto] = React.useState("")
    const [requestLoading, setRequestLoading] = React.useState(false)

    const [labourDetails, setLabourDetails] = React.useState({})

    const [errorMessage, setErrorMessage] = React.useState("")

    const videoRef = React.useRef();
    const videoHeight = 480;
    const videoWidth = 640;
    const canvasRef = React.useRef();

    const canvasRef02 = React.useRef();

    React.useEffect(() => {
        const loadModels = async () => {
          const MODEL_URL = process.env.PUBLIC_URL + '/models';
    
          Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
          ]).then(() => {
                setModelsLoaded(true)
          });
        }
        loadModels();
      }, []);

    const startVideo = () => {
        setCaptureVideo(true);
        navigator.mediaDevices
          .getUserMedia({ video: { width: 300 } })
          .then(stream => {
            let video = videoRef.current;
            if (video){
                video.srcObject = stream;
                video.play()
            }
          })
          .catch(err => {
            console.error("error:", err);
          });
      }

      const takePhoto = () => {
        if (canvasRef && canvasRef.current) {
          const currentCanvas = canvasRef.current
          currentCanvas.getContext('2d').drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
          let image_data_url = currentCanvas.toDataURL('image/jpeg');
     
          setUserPhoto(image_data_url)
          setFaceDetected(true)
        }
      }
    
      const handleVideoOnPlay = () => {
        setInterval(async () => {
          if (canvasRef && canvasRef.current) {
            canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current);
            const displaySize = {
              width: videoWidth,
              height: videoHeight
            }

            faceapi.matchDimensions(canvasRef.current, displaySize);

            const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();


            if (detections.length){
              const resizedDetections = faceapi.resizeResults(detections, displaySize);

              canvasRef02 && canvasRef02.current && canvasRef02.current.getContext('2d').clearRect(0, 0, videoWidth, videoHeight);
              canvasRef02 && canvasRef02.current && faceapi.draw.drawDetections(canvasRef02.current, resizedDetections);
              canvasRef02 && canvasRef02.current && faceapi.draw.drawFaceLandmarks(canvasRef02.current, resizedDetections);
              canvasRef02 && canvasRef02.current && faceapi.draw.drawFaceExpressions(canvasRef02.current, resizedDetections);

              // setFaceDetected(true)
              takePhoto()
            }
          }
        }, 100)
      }
    
      const closeWebcam = () => {
        videoRef.current.pause();
        videoRef.current.srcObject.getTracks()[0].stop();
        setCaptureVideo(false);
      }

    /**
     * Start Camera
     */
    useEffect(() => {
      startVideo()
      setCaptureVideo(true)
    }, [])

    /**
     * Data to blob
     */
    const dataURIToBlob = (dataURI) => {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })
    }

    /**
     * Detect face
     */
    const detectFace = () => {
        setRequestLoading(true)
        const file = dataURIToBlob(userPhoto)
        const formData = new FormData();
        formData.append('image', file, 'face.jpg') 

        if (props.detect_by_project){
          formData.append("detect_by_project", true)
        }

        // console.log("file", userPhoto)

        labourApp.detectFace(formData)
            .then(response => {
                
                  const { status, statusText, data } = response

                  if (data.labour){
                      setLabourDetails(data.labour)
                    
                      if (props.handleLabourDetected){
                        props.handleLabourDetected(data.labour, userPhoto)
                      }
                  }else{
                      setErrorMessage("Unbale to get labour data")
                  }

                  setRequestLoading(false)

            })
            .catch(error => {
                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)
                setRequestLoading(false)

                if (props.resetViews){
                  setTimeout(() => {
                    props.resetViews()
                  }, 1500)
                }
            })

    }

    /**
     * Check if face was detected
     */
    useEffect(() => {

      if (faceDetected){
        closeWebcam()

        /**
         * Send a request to detect a face
         */
        detectFace()

      }

    }, [faceDetected])

    /**
     * Render preloader
     */
    const renderPreloader = () => {
        let preloaderClassName = "face-detector-preloader-container"

        if (!modelsLoaded && captureVideo){
            preloaderClassName = preloaderClassName + " active"
        }

        return(
            <div className={preloaderClassName}>
                <div className="content-of-face-detector-preloader-container">
                    <CircularProgress />
                </div>
            </div>
        )

    }

    /**
     * Render request loading
     */
    const renderRequestLoading = (message = "Running face detection. It might take some time") => {
        return(
            <div className="request-loading-of-face-detector">
                <div className="content-of-request-loading-of-face-detector">

                    <div className="preloader-of-request-loading-of-face-detector">
                        <div className="content-of-preloader-of-request-loading-of-face-detector">
                            <CircularProgress />
                        </div>
                    </div>

                    <div className="text-box-of-request-loading-of-face-detector">
                        <div className="content-of-text-box-of-request-loading-of-face-detector">
                            <h3>Loading...</h3>
                            <p>{message}</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

      return(
          <div className="face-detector-page-container">
              <div className="content-of-face-detector-page-container">

                  {/* {renderPreloader()} */}


                  {captureVideo && (
                      <div className="camera-container-face-detector-box" style={{ width: videoWidth, height: videoHeight }}>
                          <div className="content-of-camera-container-face-detector-box">
                                <video ref={videoRef} height={videoHeight} width={videoWidth} onPlay={handleVideoOnPlay} style={{ borderRadius: '10px', zIndex: 9 }} />
                                <canvas ref={canvasRef02} style={{ position: 'absolute' }} height={videoHeight} width={videoWidth} />
                                <canvas ref={canvasRef} style={{ position: 'absolute' }} />
                                {renderRequestLoading("Opening the camera...")}
                          </div>
                      </div>
                  )}

                  {errorMessage && <ErrorPage title={"Error"} message={errorMessage} status={500} />}

                  {/* {requestLoading ? renderRequestLoading() : labourDetails.id && !requestLoading && <LabourCard {...labourDetails} />} */}
                  {requestLoading && renderRequestLoading()}

              </div>
          </div>
      )
}