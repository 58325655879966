import React, { useEffect } from 'react'
import { PageComponentProps } from '../../Router/Interfaces'
import BaseStructuredPage from '../components/BaseStructuredPage'

export default function HomePage(props: PageComponentProps) {

    useEffect(() => {
        window.location.replace("/panel/dashboard")
    }, [])

    return (
        <BaseStructuredPage>
            
            <h1>Home Page</h1>

        </BaseStructuredPage>
    )
}