import { Grid, Skeleton } from '@mui/material'
import React, { useEffect } from 'react'


export interface ItemDetailsSingleBoxProps {
    title: string,
    value: string,
    additionalClass?: string,
    multiLine?: boolean,
    htmlCode?: boolean,
    xs?: number,
    useNewDesign?: boolean,
    loading?: boolean,
    customContent?: () => void
}

export default function ItemDetailsSingleBox(props: ItemDetailsSingleBoxProps) {

    const { title, value, additionalClass, multiLine, htmlCode, xs, loading, customContent } = props

    let classContainerName = "warehouse-item-details-single-box"
    
    if (multiLine){
        classContainerName = classContainerName + " multiline"
    }
    
    if (additionalClass){
        classContainerName = classContainerName + " " + additionalClass
    }

    if (props.useNewDesign){
        return(
            <Grid item xs={xs}>
                <div className="where-is-item-single-box">
                    <div className="content-of-where-is-item-single-box">
                        {loading ? <Skeleton variant="text" width={50} /> : <h4>{title}</h4>}
                        {loading ? <Skeleton variant="text" width={100} /> : customContent ? <>{customContent()}</> : htmlCode ?  <div dangerouslySetInnerHTML={{ __html: value }} /> : <h3>{value}</h3>}
                    </div>
                </div>
            </Grid>
        )
    }

    return(
        <div className={classContainerName}>
            <div className="content-of-warehouse-item-details-single-box">

                <div className="title-section-of-warehouse-item-details-single-box">
                    <div className="content-of-title-section-of-warehouse-item-details-single-box">
                        {loading ? <Skeleton variant="text" width={50} /> : <h3>{title}</h3>}
                    </div>
                </div>

                <div className="value-section-of-warehouse-item-details-single-box">
                    <div className="content-of-value-section-of-warehouse-item-details-single-box">
                        {loading ? <Skeleton variant="text" width={100} /> : customContent ? <>{customContent()}</> : htmlCode ?  <div dangerouslySetInnerHTML={{ __html: value }} /> : <h3>{value}</h3>}
                    </div>
                </div>

            </div>
        </div>
    )
}