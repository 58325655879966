import React from 'react'
import { SingleLabourReport } from "./CheckInApp"
import LabourIDCard from "../labour/LabourIDCard"
import LabourCard from "../labour/LabourCard"
import Currencies from '../currencies/Currencies'
import DateAndTime from '../../DateAndTime/DateAndTime'
import ReportInfoBox, { ReportInfoBoxInterface } from './ReportInfoBox'
import SingleRecordBox from "./SingleRecordBox"
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import { getEmergencyFoundImg } from "./ConvertIMGToBase64"


interface Props extends SingleLabourReport {
    loading?: boolean
}

export default function LabourReport(props: Props) {

    const { labour, records, total_amount, total_hours, loading, rate } = props

    const currencies = new Currencies()
    const currentCurrency: string = currencies.currenctCurrency

    const dateAndTime = new DateAndTime()

    const details: Array<ReportInfoBoxInterface> = [
        {
            title: "Full Name:",
            value: labour.full_name,
            loading
        },
        {
            title: "Rate:",
            value: `${currentCurrency} ${rate}/hr`,
            loading
        },
        {
            title: "Total Amount:",
            value: total_amount + " " + currentCurrency,
            loading
        },
        {
            title: "Total Hours",
            value: total_hours,
            loading
        },
    ]

    return(
        <div className="single-labour-report-container">
            <div className="content-of-single-labour-report-container">

                <div className="grid-data-of-single-labour-report-container">
                    <div className="content-of-grid-data-of-single-labour-report-container">

                        <div className="miraj-id-card-box-of-single-labour-report-container">
                            <div className="content-of-miraj-id-card-box-of-single-labour-report-container">
                                <LabourCard {...labour} />
                            </div>
                        </div>

                        <div className="labour-id-card-img-box">
                            <img src={getEmergencyFoundImg(labour.passport_img)} />
                        </div>

                    </div>
                </div>

                <div className="records-of-single-labour-report-container">
                    <div className="content-of-records-of-single-labour-report-container">

                        <div className="title-of-records-of-single-labour-report-container">
                            <h3>Records:</h3>
                        </div>

                        {records.length > 0 ? records.map((record, index: number) => <SingleRecordBox index={index} key={index} {...record} />) : <GenerateMessageBox type="info" title="Nothing..." message="No records have been found" />}

                    </div>
                </div>

                <div className="check-in-report-info-box-container" style={{ marginTop: 30 }}>
                    <div className="content-of-check-in-report-info-box-container">
                        {details.map((detail: ReportInfoBoxInterface, index: number) => <ReportInfoBox key={index} {...detail} />)}
                    </div>
                </div>

            </div>
        </div>
    )
}