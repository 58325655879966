import React from 'react'
import { ReportInterface } from './CheckInApp'
import ReportProjectDetails from "./ReportProjectDetails"
import MirajLogo from "../../../assets/png/miraj-logo-dark.png"
import LabourReport from "./LabourReport"
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import ReportTotal from "./ReportTotal"

interface Props extends ReportInterface {
    loading?: boolean,
    customId?: string
}

export default function Report(props: Props) {

    const { loading, labour, project, is_all_locations, location_name, total_amount, total_hours, customId } = props

    return(
        <div className="wrapper-of-check-in-report-container">
            <div className="check-in-report-container" id={customId ? customId : "report"}>
                <div className="content-of-check-in-report-container">

                    <div className="header-of-check-in-report-container">
                        <div className="content-of-header-of-check-in-report-container">

                            <div className="logo-of-header-of-check-in-report-container">
                                <div className="content-of-logo-of-header-of-check-in-report-container">
                                    <img src={MirajLogo} />
                                </div>
                            </div>

                            <div className="title-of-header-of-check-in-report-container">
                                <div className="content-fo-title-of-header-of-check-in-report-container">
                                    <h2>Labour Timesheet Report</h2>
                                </div>
                            </div>

                        </div>
                    </div>

                    <ReportProjectDetails {...props} />

                    <div className="all-labour-in-timesheet-report-container">
                        <div className="content-of-all-labour-in-timesheet-report-container">

                            <div className="title-of-all-labour-in-timesheet-report-container">
                                <div className="content-of-title-of-all-labour-in-timesheet-report-container">
                                    <h3>Labour:</h3>
                                </div>
                            </div>

                            {labour.length > 0 ? labour.map((singleLabour, index: number) => <LabourReport key={index} {...singleLabour} />) : <GenerateMessageBox type="info" title="Nothing..." message="No labour has been found in this report" />}

                        </div>
                    </div>

                    <ReportTotal {...props} />

                    <div className="sign-box-of-check-in-report-container">
                        <div className="content-of-sign-box-of-check-in-report-container">
                            <h3>Prepared by:</h3>
                            <h3>Verifed by:</h3>
                            <h3>Approved by:</h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}