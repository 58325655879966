import React from 'react'
import { ReportInterface } from './CheckInApp'
import DateAndTime from '../../DateAndTime/DateAndTime'
import ReportInfoBox from "./ReportInfoBox"
import { ReportInfoBoxInterface } from "./ReportInfoBox"

interface Props extends ReportInterface {
    loading?: boolean
}

export default function ReportProjectDetails(props: Props) {

    const { project, is_all_locations, location_name, loading, labour_name, start_date, finish_date } = props

    const dateAndTime = new DateAndTime()

    const details: Array<ReportInfoBoxInterface> = [
        {
            title: "Project:",
            value: project.title,
            loading
        },
        {
            title: "Location:",
            value: is_all_locations ? "All locations in the project" : location_name,
            loading
        },
        {
            title: "Labour:",
            value: labour_name ? labour_name : "All labour assigned to the project",
            loading
        },
        {
            title: "Date range:",
            value: start_date && finish_date ? `${dateAndTime.formatDateAndTime(start_date)} - ${dateAndTime.formatDateAndTime(finish_date)}` : "All the dates",
            loading
        }
    ]

    return(
        <div className="check-in-report-info-box-container">
            <div className="content-of-check-in-report-info-box-container">
                {details.map((detail: ReportInfoBoxInterface, index: number) => <ReportInfoBox key={index} {...detail} />)}
            </div>
        </div>
    )
}