import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { FormControl, FormHelperText, IconButton, TextField } from '@mui/material'
import { FormElementData, FormTextFielTypes, FormTextFieldElement } from '../Interfaces'
import LoadingFieldElement from './LoadingFieldElement'
import InputAdornment from '@mui/material/InputAdornment';
import GenerateIcon from '../GenerateIcon';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs, { Dayjs } from 'dayjs';

interface Props extends FormTextFieldElement{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
    handleSubmitForm?: () => void
}

export default function TimePicker(props: Props) {

    const { id, label, data } = props

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth={props.fullWidth}>
                {data.loading ? <LoadingFieldElement label={props.label} /> : (
                     <MobileTimePicker
                        label={label}
                        value={data.value}
                        onChange={(newValue) => props.handleOnChange(id, newValue)}
                        disabled={data.disabled}
                        format="hh:mm a"
                    />
                )}
                {data.displayHelperText && (
                    <FormHelperText error={data.helperTextType === "error" ? true : false}>
                        {data.helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </LocalizationProvider>
    )
}
