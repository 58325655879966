import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import BaseApp from '../../apps/BaseApp'
import { DataDisplayerSingleSearchParam } from '../../DataDisplayer/Interfaces'
import { createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus } from '../formsDataSlice'
import { updateSelectItems } from '../formsSlice'
import { FormElementData, FormSelectElement, FormSelectSingleItem } from '../Interfaces'
import FormElementSelect from './FormElementSelect'
import { AxiosResponse } from 'axios'

interface Props extends FormSelectElement {
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
    idFieldName?: string,
    titleFieldName?: string,
    appClass?: BaseApp,
    formName?: string,
    addNoneItem?: boolean,
    customLoadFunc?: (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam> ) => Promise<AxiosResponse<any, any>>
}

export default function FormElementDynamicSelect(props: Props) {

    const { data, handleOnChange } = props
    const dispatch = useDispatch()

    const { search } = useLocation()
    const parameters = new URLSearchParams(search)

    /**
     * Update loading
     */
    const updateLoading = (loading: boolean) => dispatch(updateElementLoadingStatus({ name: props.id, loading }))

    /**
     * Update Items
     */
    const updateItemsInState = (items: Array<FormSelectSingleItem>) => dispatch(updateSelectItems({ name: props.id, items, formName: props.formName ? props.formName : "" }))

    useEffect(() => {

        if(props.appClass !== undefined && props.idFieldName !== undefined && props.titleFieldName !== undefined){
            const appClass = props.appClass
            const { idFieldName, titleFieldName } = props

            updateLoading(true)

            let searchParamsArray: Array<DataDisplayerSingleSearchParam> = []

            if (props.searchParamsArray){
                props.searchParamsArray.map((singleSearchParam: DataDisplayerSingleSearchParam) => {
                    const singleFormElementData = returnSingleFormElementData(singleSearchParam.name)

                    searchParamsArray.push({
                        name: singleFormElementData.name,
                        value: singleFormElementData.value === "none" ? "" : singleFormElementData.value
                    })

                    if (parameters.get(singleSearchParam.name)){
                        const dataValue = parameters.get(singleSearchParam.name)
            
                        if (dataValue){
                            dispatch(createOrUpdateElementData({
                                name: singleSearchParam.name,
                                value: dataValue
                            }))

                            // searchParamsArray.push({
                            //     name: singleFormElementData.name,
                            //     value: dataValue
                            // })

                            console.log("searchParamsArray", searchParamsArray)
                        }
            
                    }
                })
            }

            let loadFunc = appClass.listOfInstances(1, "", true, searchParamsArray)

            if (props.customLoadFunc){
                loadFunc = props.customLoadFunc(1, "", true, searchParamsArray)
            }

            loadFunc
                .then(response => {
                    appClass.logResults(response)
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            if (data && data.length > 0){
                                let newItems: Array<FormSelectSingleItem> = []

                                if (props.addNoneItem){
                                    if (props.subItems){
                                        newItems.push({
                                            id: "none",
                                            title: "Select Option",
                                            subItems: [{
                                                id: "none",
                                                title: "None",
                                                subItems: []
                                            }]
                                        })
                                    }else{
                                        newItems.push({
                                            id: "none",
                                            title: "None",
                                            subItems: []
                                        })
                                    }
                                }

                                interface IData {
                                    [key: string]: any
                                }

                                data.map((item: IData) => {

                                    let subItems: Array<FormSelectSingleItem> = []

                                    if (item.sub_items){
                                        if (Array.isArray(item.sub_items)){
                                            if (item.sub_items.length > 0){
                                                item.sub_items.map((subItem: IData) => subItems.push({
                                                    id: subItem.id,
                                                    title: subItem.title,
                                                    subItems: []
                                                }))
                                            }
                                        }
                                    }

                                    newItems.push({
                                        id: item[idFieldName] ? item[idFieldName] : item.id,
                                        title: item[titleFieldName] ? item[titleFieldName] : "no-name",
                                        subItems
                                    })  
                                })

                                
                                if (newItems.length > 0){
                                    updateItemsInState(newItems)
                                }

                            }
                            break

                    }

                    updateLoading(false)

                })
                .catch(error => {
                    appClass.logResults(error)
                    const { status, statusText, data } = error.response

                })

        }

    }, [])

    return(
        <React.Fragment>
            <FormElementSelect {...props} handleOnChange={handleOnChange} />
        </React.Fragment>
    )
}