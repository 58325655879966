import { AdminPanelSection } from "./Interfaces";
import RegisterAdminPage from "./components/RegisterAdminPage"
import DashboardPage from "../apps/dashboard/DashboardPage";
import ReportPage from "../apps/reports/ReportsPage"

import { ApiRequestDataInterface } from "../DataDisplayer/Interfaces";
import { IconButton } from "@mui/material";
import GenerateIcon from "../FormElements/GenerateIcon";
import UsersApp from "../apps/users/UsersApp"
import User from "../Api/User/User";

import LabourApp from "../apps/labour/LabourApp"
import DesignationsApp from "../apps/designations/DesignationsApp"
import ProjectsApp from "../apps/projects/ProjectsApp"
import LocationsApp from "../apps/locations/LocationsApp"

import Currencies from "../apps/currencies/Currencies"
import TeamsApp from "../apps/teams/TeamsApp"
import RegistrationCountersApp from "../apps/registration_counters/RegistrationCountersApp"
import ReportsPage from "../apps/reports/ReportsPage";
import SingleLocationField from "../apps/locations/SingleLocationField"

let registeredAdminPages: Array<AdminPanelSection> = []

/**
 * Register Dashboard Page
 */
 registeredAdminPages.push(RegisterAdminPage({
    type: "page",
    name: "dashboard",
    title: "Dashboard",
    icon: "home",
    usersAllowed: {
        onlyAdmin: true,
        onlySuperUser: false,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: false,
        editPage: false,
        viewPage: false
    },
    pageComponent: DashboardPage,
    disableSectionTitleBox: true
}))

/**
 * Labour
 */
const labourPrefix = "labour_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "labour",
     title: "Labour",
     icon: "labour",
     usersAllowed: {
         onlyAdmin: true,
         onlySuperUser: false,
         anyUser: false
     },
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: true
     },
     forms: {
         defaultForm: {
             name: labourPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 6,
                     id: labourPrefix + "full_name",
                     label: "Full Name",
                     fullWidth: true
                 },
                 {
                    type: "DynamicSelect",
                    xs: 3,
                    id: labourPrefix + "designation_id",
                    label: "Designation",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new DesignationsApp(),
                    addNoneItem: false,
                    multiline: true,
                    formName: labourPrefix + "default_form"
                },
                {
                    type: "DynamicSelect",
                    xs: 3,
                    id: labourPrefix + "team_id",
                    label: "Team",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new TeamsApp(),
                    addNoneItem: false,
                    multiline: true,
                    formName: labourPrefix + "default_form"
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: labourPrefix + "passport_number",
                    label: "ID/Passport Number",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: labourPrefix + "phone",
                    label: "Phone",
                    fullWidth: true
                },
                {
                    type: "CurrencyField",
                    fieldType: "number",
                    xs: 6,
                    id: labourPrefix + "rate",
                    label: "Rate",
                    fullWidth: true
                },
                {
                    type: "Select",
                    xs: 6,
                    id: labourPrefix + "currency",
                    label: "Currency",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    addNoneItem: false,
                    items: new Currencies().currencies
                },
                {
                    type: "ImageUploader",
                    xs: 12,
                    id: labourPrefix + "passport_img",
                    label: "ID/Passport",
                    description: "Upload copy of ID or Passport",
                    idCardUploader: true
                 },
                 {
                     type: "ImageUploader",
                     xs: 12,
                     id: labourPrefix + "photo",
                     label: "Photo",
                     description: "Upload labour image",
                     isUserImage: true
                  },
                  {
                     type: "Button",
                     xs: 12,
                     id: labourPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
        {
            name: labourPrefix + "unique_id",
            value: "1234"
        },
         {
             name: labourPrefix + "passport_number",
             value: ""
         },
         {
            name: labourPrefix + "team_id",
            value: ""
        },
         {
             name: labourPrefix + "passport_img",
             value: ""
         },
         {
             name: labourPrefix + "full_name",
             value: ""
         },
         {
            name: labourPrefix + "designation_id",
            value: ""
         },
         {
            name: labourPrefix + "rate",
            value: ""
         },
         {
            name: labourPrefix + "currency",
            value: "SAR"
         },
         {
            name: labourPrefix + "phone",
            value: ""
         },
         {
            name: labourPrefix + "photo",
            value: ""
         },
         {
            name: labourPrefix + "submit",
            value: ""
        },
     ],
     appClass: new LabourApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No labour have been found",
        sureToDelete: "Are you sure you want to delete this labour?",
        addNewText: "Add new labour"
     },
     dataDisplayerFields: [
        {
            name: "photo",
            title: "Thumbnail",
            type: "thumbnail"
        },
        {
            name: "full_name",
            title: "Name",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "team_name",
            title: "Team",
            type: "text",
            minWidth: 200,
            maxWidth: 200,
            customContent(data) {
                return <h3>{data.team_name ? data.team_name : "-"}</h3>
            },
        },
        {
            name: "designation_name",
            title: "Designation",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "passport_number",
            title: "ID/Password Number",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "phone",
            title: "Phone Number",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "unique_id",
            title: "Unique ID",
            type: "text",
            minWidth: 300,
            maxWidth: 300
        },
        
     ],
     enableSearch: true,
     customDataDisplayerButtons: [
        {
            type: "view",
            label: "View",
            icon: ""
        },
        {
            type: "edit",
            label: "Edit",
            icon: ""
        },
        {
            type: "delete",
            label: "Delete",
            icon: ""
        }
    ]
 }))

/**
 * Teams
 */
 const teamsPrefix = "teams_"
 registeredAdminPages.push(RegisterAdminPage({
      type: "section",
      name: "teams",
      title: "Teams",
      icon: "users",
      usersAllowed: {
          onlyAdmin: true,
          onlySuperUser: false,
          anyUser: false
      },
      displayInMenu: true,
      optionalPages: {
          addNewPage: true,
          editPage: true,
          viewPage: false
      },
      forms: {
          defaultForm: {
              name: teamsPrefix + "default_form",
              elements: [
                  {
                      type: "TextField",
                      fieldType: "text",
                      xs: 12,
                      id: teamsPrefix + "title",
                      label: "Title",
                      fullWidth: true
                  },
                  {
                    type: "ImageUploader",
                    xs: 12,
                    id: teamsPrefix + "thumbnail",
                    label: "Thumbnail",
                    description: "Upload team photo",
                 },
                   {
                      type: "Button",
                      xs: 12,
                      id: teamsPrefix + "submit",
                      label: "Submit",
                      fullWidth: false,
                      onClick: () => {},
                      isSubmit: true
                  }
              ]
          }
      },
      formsData: [
          {
              name: teamsPrefix + "title",
              value: ""
          },
          {
            name: teamsPrefix + "thumbnail",
            value: ""
        },
          {
             name: teamsPrefix + "submit",
             value: ""
         },
      ],
      appClass: new TeamsApp(),
      dataDisplayerDefaultMessages: {
         notFound: "No teams have been found",
         sureToDelete: "Are you sure you want to delete this team?",
         addNewText: "Add new teams"
      },
      dataDisplayerFields: [
         {
             name: "thumbnail",
             title: "Thumbnail",
             type: "thumbnail"
         },
         {
             name: "title",
             title: "Title",
             type: "text",
             minWidth: 200,
             maxWidth: 200
         },
      ],
      enableSearch: true,
      customDataDisplayerButtons: [
        {
            type: "view",
            label: "View",
            icon: ""
        },
        {
            type: "edit",
            label: "Edit",
            icon: ""
        },
        {
            type: "delete",
            label: "Delete",
            icon: ""
        }
    ]
  }))

 /**
 * Designations
 */
 const designationsPrefix = "designations_"
 registeredAdminPages.push(RegisterAdminPage({
      type: "section",
      name: "designations",
      title: "Designations",
      icon: "assignment-id",
      usersAllowed: {
          onlyAdmin: true,
          onlySuperUser: false,
          anyUser: false
      },
      displayInMenu: true,
      optionalPages: {
          addNewPage: true,
          editPage: true,
          viewPage: false
      },
      forms: {
          defaultForm: {
              name: designationsPrefix + "default_form",
              elements: [
                  {
                      type: "TextField",
                      fieldType: "text",
                      xs: 12,
                      id: designationsPrefix + "title",
                      label: "Title",
                      fullWidth: true
                  },

                   {
                      type: "Button",
                      xs: 12,
                      id: designationsPrefix + "submit",
                      label: "Submit",
                      fullWidth: false,
                      onClick: () => {},
                      isSubmit: true
                  }
              ]
          }
      },
      formsData: [
          {
              name: designationsPrefix + "title",
              value: ""
          },
          {
             name: designationsPrefix + "submit",
             value: ""
         },
      ],
      appClass: new DesignationsApp(),
      dataDisplayerDefaultMessages: {
         notFound: "No designations have been found",
         sureToDelete: "Are you sure you want to delete this designation?",
         addNewText: "Add new designation"
      },
      dataDisplayerFields: [
         {
             name: "thumbnail",
             title: "Thumbnail",
             type: "thumbnail"
         },
         {
             name: "title",
             title: "Title",
             type: "text",
             minWidth: 200,
             maxWidth: 200
         },
      ],
      enableSearch: true,
  }))
 

/**
 * Projects
 */
const projectsPrefix = "projects_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "projects",
     title: "Projects",
     icon: "assignment",
     usersAllowed: {
         onlyAdmin: true,
         onlySuperUser: false,
         anyUser: false
     },
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: false
     },
     forms: {
         defaultForm: {
             name: projectsPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 12,
                     id: projectsPrefix + "title",
                     label: "Title",
                     fullWidth: true
                 },
                 {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: projectsPrefix + "description",
                    label: "Description",
                    fullWidth: true,
                    multiline: true,
                    maxRows: 4,
                    minRows: 4
                },
                {
                    type: "SelectLocationsField",
                    xs: 12,
                    id: projectsPrefix + "location_ids",
                    label: "Select Locations",
                },
                {
                    type: "ImageUploader",
                    xs: 12,
                    id: projectsPrefix + "thumbnail",
                    label: "Thumbnail",
                    description: "Here you can upload the project picture"
                 },
                  {
                     type: "Button",
                     xs: 12,
                     id: projectsPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
         {
             name: projectsPrefix + "title",
             value: ""
         },
         {
             name: projectsPrefix + "description",
             value: ""
         },
         {
             name: projectsPrefix + "location_ids",
             value: ""
         },
         {
            name: projectsPrefix + "thumbnail",
            value: ""
         },
         {
            name: projectsPrefix + "submit",
            value: ""
        },
     ],
     appClass: new ProjectsApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No projects have been found",
        sureToDelete: "Are you sure you want to delete this project?",
        addNewText: "Add new project"
     },
     dataDisplayerFields: [
        {
            name: "thumbnail",
            title: "Thumbnail",
            type: "thumbnail"
        },
        {
            name: "title",
            title: "Title",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
     ],
     enableSearch: true,
     customDataDisplayerButtons: [
        {
            type: "view",
            label: "View",
            icon: ""
        },
        {
            type: "edit",
            label: "Edit",
            icon: ""
        },
        {
            type: "delete",
            label: "Delete",
            icon: ""
        }
    ]
 }))

 /**
 * Locations
 */
const locationsPrefix = "locations_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "locations",
     title: "Locations",
     icon: "location",
     usersAllowed: {
         onlyAdmin: true,
         onlySuperUser: false,
         anyUser: false
     },
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: false
     },
     forms: {
         defaultForm: {
             name: locationsPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 12,
                     id: locationsPrefix + "title",
                     label: "Title",
                     fullWidth: true
                 },
                 {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: locationsPrefix + "address",
                    label: "Address",
                    fullWidth: true,
                    multiline: true,
                    maxRows: 4,
                    minRows: 4
                },
                {
                    type: "ImageUploader",
                    xs: 12,
                    id: locationsPrefix + "thumbnail",
                    label: "Thumbnail",
                    description: "Here you can upload the project picture"
                 },
                  {
                     type: "Button",
                     xs: 12,
                     id: locationsPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
         {
             name: locationsPrefix + "title",
             value: ""
         },
         {
             name: locationsPrefix + "description",
             value: ""
         },
         {
             name: locationsPrefix + "location_ids",
             value: ""
         },
         {
            name: locationsPrefix + "thumbnail",
            value: ""
         },
         {
            name: locationsPrefix + "submit",
            value: ""
        },
     ],
     appClass: new LocationsApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No locations have been found",
        sureToDelete: "Are you sure you want to delete this location?",
        addNewText: "Add new location"
     },
     dataDisplayerFields: [
        {
            name: "thumbnail",
            title: "Thumbnail",
            type: "thumbnail"
        },
        {
            name: "title",
            title: "Title",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "address",
            title: "Address",
            type: "text",
            minWidth: 400,
            maxWidth: 400
        },
     ],
     enableSearch: true,
 }))

/**
 * Registration Counters
 */
const registrationCountersPrefix = "counters_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "counters",
     title: "Registration Counters",
     icon: "registration",
     usersAllowed: {
         onlyAdmin: true,
         onlySuperUser: false,
         anyUser: false
     },
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: false
     },
     forms: {
         defaultForm: {
             name: registrationCountersPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 12,
                     id: registrationCountersPrefix + "title",
                     label: "Title",
                     fullWidth: true
                 },
                 {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: registrationCountersPrefix + "description",
                    label: "Description",
                    fullWidth: true,
                    multiline: true,
                    maxRows: 4,
                    minRows: 4
                },
                 {
                    type: "DynamicSelect",
                    xs: 6,
                    id: registrationCountersPrefix + "project_id",
                    label: "Project",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new ProjectsApp(),
                    addNoneItem: false,
                    formName: registrationCountersPrefix + "default_form"
                },
                 {
                    type: "DynamicSelect",
                    xs: 6,
                    id: registrationCountersPrefix + "location_id",
                    label: "Location",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new LocationsApp(),
                    addNoneItem: false,
                    formName: registrationCountersPrefix + "default_form"
                },
                {
                    type: "DateAndTimeField",
                    fieldType: "text",
                    xs: 12,
                    id: registrationCountersPrefix + "token_expires",
                    label: "Token Expires",
                    fullWidth: true
                },
                 {
                   type: "ImageUploader",
                   xs: 12,
                   id: registrationCountersPrefix + "thumbnail",
                   label: "Thumbnail",
                   description: "Upload counter photo",
                },
                  {
                     type: "Button",
                     xs: 12,
                     id: registrationCountersPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
         {
             name: registrationCountersPrefix + "title",
             value: ""
         },
         {
            name: registrationCountersPrefix + "description",
            value: ""
        },
        {
            name: registrationCountersPrefix + "project_id",
            value: ""
        },
        {
            name: registrationCountersPrefix + "location_id",
            value: ""
        },
         {
           name: registrationCountersPrefix + "thumbnail",
           value: ""
        },
        {
            name: registrationCountersPrefix + "token_expires",
            value: ""
         },
         {
            name: registrationCountersPrefix + "submit",
            value: ""
        },
     ],
     appClass: new RegistrationCountersApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No counters have been found",
        sureToDelete: "Are you sure you want to delete this counter?",
        addNewText: "Add new counter"
     },
     dataDisplayerFields: [
        {
            name: "thumbnail",
            title: "Thumbnail",
            type: "thumbnail"
        },
        {
            name: "title",
            title: "Title",
            type: "text",
            minWidth: 300,
            maxWidth: 300
        },
        {
            name: "project_name",
            title: "Project",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "location_name",
            title: "Location",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "user_name",
            title: "Created by",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
     ],
     enableSearch: true,
     customDataDisplayerButtons: [
        {
            type: "view",
            label: "View",
            icon: ""
        },
        {
            type: "edit",
            label: "Edit",
            icon: ""
        },
        {
            type: "delete",
            label: "Delete",
            icon: ""
        }
     ]
 }))

/**
 * Register Reports Page
 */
registeredAdminPages.push(RegisterAdminPage({
    type: "page",
    name: "reports",
    title: "Reports",
    icon: "report",
    usersAllowed: {
        onlyAdmin: true,
        onlySuperUser: false,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: false,
        editPage: false,
        viewPage: false
    },
    pageComponent: ReportsPage,
    disableSectionTitleBox: true
}))

/**
 * Register users
 */
const usersPrefix = "users_"
registeredAdminPages.push(RegisterAdminPage({
    type: "section",
    name: "users",
    title: "Users",
    icon: "users",
    usersAllowed: {
        onlyAdmin: false,
        onlySuperUser: true,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: true,
        editPage: false,
        viewPage: false
    },
    forms: {
        defaultForm: {
            name: usersPrefix + "default_form",
            elements: [
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: usersPrefix + "first_name",
                    label: "First Name",
                    fullWidth: true
                },
                {
                   type: "TextField",
                   fieldType: "text",
                   xs: 6,
                   id: usersPrefix + "last_name",
                   label: "Last Name",
                   fullWidth: true
               },
               {
                   type: "TextField",
                   fieldType: "email",
                   xs: 6,
                   id: usersPrefix + "email",
                   label: "Email",
                   fullWidth: true
               },
               {
                   type: "Select",
                   xs: 6,
                   id: usersPrefix + "type",
                   label: "Type",
                   fullWidth: true,
                   items: [
                       {
                           id: "admin",
                           title: "Admin"
                       },
                       {
                           id: "staff",
                           title: "Staff"
                       },
                    //    {
                    //        id: "guest",
                    //        title: "Guest"
                    //    }
                   ]
               },
               {
                   type: "ImageUploader",
                   xs: 12,
                   id: usersPrefix + "thumbnail",
                   label: "Thumbnail",
                   description: "Here you can upload the user picture"
                },
               {
                    type: "Button",
                    xs: 12,
                    id: usersPrefix + "submit",
                    label: "Submit",
                    fullWidth: false,
                    onClick: () => {},
                    isSubmit: true
                }
            ]
        }
    },
    formsData: [
        {
            name: usersPrefix + "first_name",
            value: ""
        },
        {
           name: usersPrefix + "last_name",
           value: ""
       },
       {
           name: usersPrefix + "email",
           value: ""
       },
       {
           name: usersPrefix + "type",
           value: ""
       },
       {
           name: usersPrefix + "thumbnail",
           value: ""
       },
       {
           name: usersPrefix + "submit",
           value: ""
       },
    ],
    appClass: new UsersApp(),
    dataDisplayerDefaultMessages: {
       notFound: "No users have been found",
       sureToDelete: "Are you sure you want to delete this user?",
       addNewText: "Add new user"
    },
    dataDisplayerFields: [
       {
           name: "thumbnail",
           title: "Thumbnail",
           type: "thumbnail"
       },
       {
           name: "first_name",
           title: "Name",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.first_name && details.last_name ? <h3>{details.first_name} {details.last_name}</h3>: ""
           }
       },
       {
           name: "last_name",
           title: "Type",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.type ? <h3>{new User().returnUserTypeTitle(details.type)}</h3> : <h3>Guest</h3>
           }
       },
       {
           name: "company_id",
           title: "Company",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.company_name ? <h3>{details.company_name}</h3>: "-"
           }
       },
       {
           name: "email",
           title: "Email",
           type: "text"
       },
    ],
    enableSearch: true,
    customDataDisplayerButtons: [
       {
           type: "delete",
           label: "Delete",
           icon: ""
       }
   ]
}))


/**
 * Export admin pages
 */
export const adminPages = registeredAdminPages