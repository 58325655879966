import React, { useState } from 'react'
import { DataDisplayerProps, DataDisplayerSingleSearchParam } from '../../DataDisplayer/Interfaces'
import DataDisplayer from '../../DataDisplayer/DataDisplayer'
import ProjectsApp from '../projects/ProjectsApp'
import AdminPanel from '../../AdminPanel/AdminPanel'
import { Button, CircularProgress, IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import LabourAssignedToProjectApp from "./LabourAssignedToProjectApp"
import CreateLabourAssignedModal from "./CreateLabourAssignedModal"
import DeleteLabourButton from "./DeleteLabourButton"

interface Props {
    projectId: number,
    locationId: number | boolean
}

export default function LabourAssignedList(props: Props) {
    
    const { projectId, locationId } = props
    const labourAssignedToProjectApp = new LabourAssignedToProjectApp()

    const [labourModalOpen, setLabourModalOpen] = useState<boolean>(false)
    const [refreshData, setRefreshData] = useState<boolean>(false)

    const [labourUpdate, setLabourUpdate] = useState<boolean>(false)
    const [labourAssignedId, setLabourAssignedId] = useState<number>(0)


    /**
     * Delete Labour
     */
    const deleteLabour = (id: number) => {
        labourAssignedToProjectApp.deleteInstance(id)
            .then(response => {
                
                const { status } = response

                switch(status){

                    case 200:
                        setRefreshData(true)
                        break

                }

            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                alert(message)
            })
    }

    /**
     * Load Items
     */
    const loadItems = (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam> ) => labourAssignedToProjectApp.labourAssignedList(projectId, locationId, page, search, disablePagination, searchParams)

    const dataDisplayerProps: DataDisplayerProps = {
        sectionName: "labourassigned",
        defaultMessages:  {
            notFound: "No labour have been assigned to this project",
            sureToDelete: "Are you sure you want to delete this labour from the project?",
            addNewText: "Add new labour"
         },
        appClass: labourAssignedToProjectApp,
        enableSearch: false,
        loadItems: loadItems,
        customDataDisplayerFields: [
            {
                type: "thumbnail",
                name: "thumbnail",
                title: ""
            },
            {
                type: "text",
                name: "labour",
                title: "Name:",
                maxWidth: 200,
                minWidth: 200,
                customContent(data) {
                    return <h3>{data.labour.full_name ? data.labour.full_name : "-"}</h3>
                },
            },
            {
                type: "text",
                name: "labour",
                title: "Designation:",
                maxWidth: 200,
                minWidth: 200,
                customContent(data) {
                    return <h3>{data.labour.designation_name ? data.labour.designation_name : "-"}</h3>
                },
            },
            {
                type: "text",
                name: "project_name",
                title: "Project:",
                maxWidth: 200,
                minWidth: 200,
            },
            {
                type: "text",
                name: "location_name",
                title: "Location:",
                maxWidth: 200,
                minWidth: 200,
            },
            {
                type: "text",
                name: "shift",
                title: "Shift:",
                maxWidth: 200,
                minWidth: 200,
                customContent(data) {
                    return <h3>{data.shift ? labourAssignedToProjectApp.returnShiftTypeTitle(data.shift) : "-"}</h3>
                },
            },
            {
                type: "text",
                name: "labour",
                title: "Unique ID:",
                maxWidth: 250,
                minWidth: 250,
                customContent(data) {
                    return <h3>{data.labour.unique_id ? data.labour.unique_id : "-"}</h3>
                },
            },
        ],
        customDataDisplayerButtons: [
            {
                type: "custom",
                label: "Edit",
                icon: "",
                customContent(details) {
                    return <IconButton onClick={() => {
                        setLabourAssignedId(details.id)
                        setLabourUpdate(true)
                        handleOpenLabourModalForm()
                    }}><GenerateIcon icon="edit" /></IconButton>
                },
            },
            {
                type: "custom",
                label: "Delete",
                icon: "",
                customContent(details) {
                    return <DeleteLabourButton id={details.id} handleDelete={deleteLabour} />
                },
            }
        ]
    }

    /**
     * Close Labour Modal
     */
    const handleCloseLabourModalForm = () => setLabourModalOpen(false)

    /**
     * Open Labour Modal
     */
    const handleOpenLabourModalForm = () => setLabourModalOpen(true)

    return(
        <div className="assigned-labour-to-the-project-container">
            <div className="content-of-assigned-labour-to-the-project-containe">

                <CreateLabourAssignedModal open={labourModalOpen} handleClose={handleCloseLabourModalForm} projectId={projectId} refreshResults={() => setRefreshData(true)} update={labourUpdate} labourAssignedId={labourAssignedId} />

                <div className="title-box-of-admin-panel-single-section-container">
                    <div className="content-of-title-box-of-admin-panel-single-section-container">
                        <h3>Labour Assigned
                            <IconButton style={{ marginLeft: 5, marginTop: -2 }} onClick={() => handleOpenLabourModalForm()}>
                                <GenerateIcon icon="add" />
                            </IconButton>
                        </h3>
                    </div>
                </div>

                <div className="list-box-of-assigned-labour-to-the-project-container">
                    <div className="content-of-list-box-of-assigned-labour-to-the-project-container">
                        <DataDisplayer {...dataDisplayerProps} refreshData={refreshData} setRefreshData={setRefreshData} />
                    </div>
                </div>

            </div>
        </div>
    )
}