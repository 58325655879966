import React from 'react'
import { useParams } from 'react-router-dom'
import ListOfTeamMembers from "./ListOfTeamMembers"

export default function ViewTeamPage() {

    const params = useParams()
    const teamId = params.id ? Number(params.id) : 0

    return <ListOfTeamMembers teamId={teamId} />
}