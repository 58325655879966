import React from 'react'
import { LabourInterface } from './LabourApp'
import { Skeleton } from '@mui/material'
import LabourCard from './LabourCard'

interface Props extends LabourInterface {
    loading?: boolean
}

export default function LabourMirajCardBox(props: Props) {

    const { loading } = props

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <div className="title-box-of-admin-panel-single-section-container">
                    <div className="content-of-title-box-of-admin-panel-single-section-container">
                        <h3>{loading ? <Skeleton variant="text" width={100} /> : "Miraj ID"}</h3>
                    </div>
                </div>

                <div className="miraj-id-labour-container-info-box">
                    <div className="content-of-miraj-id-labour-container-info-box">
                        {loading ? <Skeleton variant="rectangular" width="100%" height={450} /> : <LabourCard {...props} />}
                    </div>
                </div>


            </div>
        </div>
    )
}