export const getEmergencyFoundImg = (urlImg) => {
    // var img = new Image();
    // img.src = urlImg;
    // img.crossOrigin = 'Anonymous';
  
    // var canvas = document.createElement('canvas'),
    // ctx = canvas.getContext('2d');
  
    // canvas.height = img.naturalHeight;
    // canvas.width = img.naturalWidth;
   
    // ctx.drawImage(img, 0, 0);
  
    // // var b64 = canvas.toDataURL('image/png').replace(/^data:image.+;base64,/, '');
    // var b64 = canvas.toDataURL('image/png')
    // return b64;
    return urlImg
} 