import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CheckInApp, { CheckInInterface } from './CheckInApp'
import { addNewForm } from '../../FormElements/formsSlice'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus } from '../../FormElements/formsDataSlice'

import LabourApp from '../labour/LabourApp'
import LocationsApp from '../locations/LocationsApp'
import ProjectsApp from '../projects/ProjectsApp'
import FormValidator from '../../FormElements/FormValidator'
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import { FormAlertTypes } from '../../FormElements/Types'
import { CircularProgress } from '@mui/material'
import GenerateForm from '../../FormElements/GenerateForm'
import DateAndTime from '../../DateAndTime/DateAndTime'

interface Props {
    update?: boolean,
    checkInId?: number,
    closeModal: () => void,
    refreshResults: () => void,
    projectId?: number,
    locationId?: number,
    labourId?: number
}

export default function CheckInForm(props: Props) {

    const { update, checkInId, closeModal, refreshResults, projectId, locationId, labourId } = props

    const dispatch = useDispatch()
    const checkInApp = new CheckInApp()
    
    const prefix: string = "check_in_form_"
    const formName: string = "check_in_form"

    const [loading, setLoading] = useState<boolean>(false)

    const dateAndTime = new DateAndTime()

    /**
     * Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(addNewForm({
            name: formName,
            elements: [
                {
                    type: "DateAndTimeField",
                    fieldType: "text",
                    xs: 6,
                    id: prefix + "check_in",
                    label: "Check-in",
                    fullWidth: true
                },
                {
                    type: "DateAndTimeField",
                    fieldType: "text",
                    xs: 6,
                    id: prefix + "check_out",
                    label: "Check-out",
                    fullWidth: true
                },
                {
                    type: "DynamicSelect",
                    xs: 12,
                    id: prefix + "labour_id",
                    label: "Labour",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "full_name",
                    appClass: new LabourApp(),
                    addNoneItem: false,
                    formName: formName,
                },
                {
                    type: "DynamicSelect",
                    xs: 12,
                    id: prefix + "location_id",
                    label: "Location",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new LocationsApp(),
                    addNoneItem: false,
                    formName: formName,
                },
                {
                    type: "DynamicSelect",
                    xs: 12,
                    id: prefix + "project_id",
                    label: "Project",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new ProjectsApp(),
                    addNoneItem: false,
                    formName: formName,
                },
                {
                    type: "Alert",
                    xs: 12,
                    label: "",
                    id: prefix + "alert"
                },
                {
                     type: "Button",
                     xs: 12,
                     id: prefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 },
            ]
        }))

         /**
         * Update form data
         */
         dispatch(addManyElementsData([
            {
                name: prefix + "check_in",
                value: ""
            },
            {
                name: prefix + "check_out",
                value: ""
            },
            {
                name: prefix + "labour_id",
                value: labourId ? labourId : ""
            },
            {
                name: prefix + "location_id",
                value: locationId ? locationId : ""
            },
            {
                name: prefix + "project_id",
                value: projectId ? projectId : ""
            },
            {
                name: prefix + "alert",
                value: "Test",
                severity: "info",
                hidden: true
            },
            {
                name: prefix + "submit",
                value: ""
            },
        ]))

    }, [])

    /**
     * Load check details
     */
    const loadCheckInDetails = () => {
        if (props.update && props.checkInId){
            setLoading(true)

            checkInApp.getSingleInstanceDetails(props.checkInId)
                .then(response => {
                                
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            setLoading(false)
                            const chekIn: CheckInInterface = data
                        
                            if (chekIn){
                                console.log("chekIn", chekIn)
                                updateElementData("labour_id", chekIn.labour_id)
                                updateElementData("location_id", data.location_id)
                                updateElementData("project_id", data.project_id)
                                updateElementData("check_in", data.check_in)
                                updateElementData("check_out", data.check_out)
                            }

                            break

                    }


                })
                .catch(error => {
                    setLoading(false)
                    
                    const response = error.response
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                    checkInApp.logResults(response)

                    switch(response.status){

                        default:
                            updateAlert("error", message)
                    }
                
                })
        }
    }

    /**
     * Get check in details
     */
    useEffect(() => {
        console.log("props", props)
        loadCheckInDetails()
    }, [props.checkInId])

    /**
    * Update button loading
    */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: prefix + "submit",
                loading
            })
        )
    }

    /**
     * Update Alert
     */
    const updateAlert = (severity: FormAlertTypes, message: string) =>  dispatch(
        createOrUpdateElementData({
            name: prefix + "alert",
            value: message,
            severity: severity
        })
    )

    /**
     * Get single form element data
     */
    const getSingleFormElementData = (name: string) => returnSingleFormElementData(prefix + name).value

    /**
     * Submit form
     */
    const submitForm = () => {
        updateButtonLoadingStatus(true)

        const check_in = getSingleFormElementData("check_in")
        const check_out = getSingleFormElementData("check_out")

        let data: AxiosDefaultDataObject = {
            labour_id: getSingleFormElementData("labour_id"),
            location_id: getSingleFormElementData("location_id"),
            project_id: getSingleFormElementData("project_id"),
            checked_in_by_labour: false,
            update: props.update
        }

        if (check_in){
            data.check_in = check_in ? dateAndTime.dateJsFormatDateAndTime(check_in) : ""
        }

        if (check_out){
            data.check_out = check_out ? dateAndTime.dateJsFormatDateAndTime(check_out) : ""
        }

        if (props.update && props.checkInId){
            data.id = props.checkInId
        }

        const formValidator = new FormValidator(checkInApp.requiredFields)

        if (formValidator.isValid().valid){

            checkInApp.createNewInstance(data)
                .then(response => {
                            
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            props.refreshResults()
                            props.closeModal()
                            break

                    }

                    updateButtonLoadingStatus(false)

                })
                .catch(error => {
                    updateButtonLoadingStatus(false)
                    
                    const response = error.response
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                    checkInApp.logResults(response)

                    switch(response.status){

                        default:
                        updateAlert("error", message)
                    }
                
                })

        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }

    }

    /**
     * Update element data
     */
    const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: prefix + name, value }))

    /**
     * Generate preloader
     */
    const generatePreloader = () => {
        let className: string = "preloader-of-labour-assigned-form-container"
        if (loading){
            className += " active"
        }

        return(
            <div className={className}>
                <div className="content-of-preloader-of-labour-assigned-form-container">
                    <CircularProgress />
                </div>
            </div>
        )
    }

    return(
        <div className="labour-assigned-form-container">
            <div className="content-of-labour-assigned-form-container">
                {generatePreloader()}
                <GenerateForm name={formName} handleSubmitForm={submitForm} />
            </div>
        </div>
    )
}