import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus } from '../../FormElements/formsDataSlice';
import { addNewForm } from '../../FormElements/formsSlice';
import LocationsApp from './LocationsApp';
import GenerateForm from '../../FormElements/GenerateForm';
import DialogActions from '@mui/material/DialogActions';

interface Props {
    open: boolean,
    handleClose: () => void,
    addLocation: (id: number) => void
}

export default function SelectLocationModal(props: Props) {

    const { open, handleClose, addLocation } = props

    const dispatch = useDispatch()

    const locationsApp = new LocationsApp()

    /**
     * Form
     */
    const formDetails = {
        name: "select_location_modal_form",
        elemPrefix: "select_location_modal_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    useEffect(() => {

        /**
         * New form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "DynamicSelect",
                        xs: 12,
                        id: elemPrefix + "location_id",
                        label: "Location",
                        fullWidth: true,
                        idFieldName: "id",
                        titleFieldName: "title",
                        appClass: new LocationsApp(),
                        addNoneItem: false,
                        multiline: true,
                        formName: formDetails.name
                    },
                ]
            })
        )

        /**
         * Form Data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "location_id",
                    value: ""
                }
            ])
        )

    }, [])

    /**
     * Close modal
     */
    const closeModal = () => {
        handleClose()
        dispatch(createOrUpdateElementData({
            name: elemPrefix + "location_id",
            value: ""
        }))
    }

    /**
     * New location
     */
    const handleAddNewLocation = () => {
        addLocation(returnSingleFormElementData(elemPrefix + "location_id").value)
        closeModal()
    }

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
      >
            <DialogTitle id="alert-dialog-title">
                Add new location
            </DialogTitle>

            <DialogContent>
                <div style={{ marginTop: 5 }}>
                    <GenerateForm name={formDetails.name} />
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleAddNewLocation()}>Add</Button>
                <Button onClick={() => closeModal()}>Close</Button>
            </DialogActions>

        </Dialog>
    )
}