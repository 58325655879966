import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LabourAssignedToProjectApp from './LabourAssignedToProjectApp'
import { addNewForm } from '../../FormElements/formsSlice'
import LabourApp, { LabourInterface } from "./LabourApp"
import { DataDisplayerSingleSearchParam } from '../../DataDisplayer/Interfaces'
import LocationsApp from '../locations/LocationsApp'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus } from '../../FormElements/formsDataSlice'
import GenerateForm from '../../FormElements/GenerateForm'
import { FormAlertTypes } from '../../FormElements/Types'
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import FormValidator from '../../FormElements/FormValidator'
import { CircularProgress } from '@mui/material'

interface Props {
    update?: boolean,
    projectId: number,
    closeModal: () => void,
    refreshResults: () => void,
    labourAssignedId?: number
}

export default function LabourAssignedForm(props: Props) {

    const dispatch = useDispatch()
    const labourAssignedToProjectApp = new LabourAssignedToProjectApp()
    const labourApp = new LabourApp()

    const locationsApp = new LocationsApp()

    const prefix: string = "labour_assigned_form_"
    const formName: string = "create_labour_assigned_form"

    const [loading, setLoading] = useState<boolean>(false)

    /**
     * Load locations
     */
    const customLoadLocationsFunc = (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam> ) => locationsApp.locationsList(props.projectId, page, search, disablePagination, searchParams)

    useEffect(() => {

        /**
         * Create Form
         */
        dispatch(addNewForm({
            name: "create_labour_assigned_form",
            elements: [
                {
                    type: "DynamicSelect",
                    xs: 12,
                    id: prefix + "labour_id",
                    label: "Labour",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "full_name",
                    appClass: new LabourApp(),
                    addNoneItem: false,
                    formName: formName,
                },
                {
                    type: "DynamicSelect",
                    xs: 12,
                    id: prefix + "location_id",
                    label: "Location",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new LocationsApp(),
                    addNoneItem: false,
                    formName: formName,
                    customLoadFunc: customLoadLocationsFunc
                },
                {
                    type: "TextField",
                    fieldType: "number",
                    xs: 3,
                    id: prefix + "break_time",
                    label: "Break Time Number",
                    fullWidth: true
                },
                {
                    type: "Select",
                    xs: 9,
                    id: prefix + "break_type",
                    label: "Break Time",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    addNoneItem: false,
                    items: labourAssignedToProjectApp.breakTimeTypes
                },
                {
                    type: "Select",
                    xs: 12,
                    id: prefix + "shift",
                    label: "Shift",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    addNoneItem: false,
                    items: labourAssignedToProjectApp.shiftsTypes
                },
                {
                    type: "Alert",
                    xs: 12,
                    label: "",
                    id: prefix + "alert"
                },
                {
                     type: "Button",
                     xs: 12,
                     id: prefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 },
            ]
        }))

        /**
         * Update form data
         */
        dispatch(addManyElementsData([
            {
                name: prefix + "labour_id",
                value: ""
            },
            {
                name: prefix + "location_id",
                value: ""
            },
            {
                name: prefix + "shift",
                value: "day"
            },
            {
                name: prefix + "break_time",
                value: 1
            },
            {
                name: prefix + "break_type",
                value: "hours"
            },
            {
                name: prefix + "alert",
                value: "Test",
                severity: "info",
                hidden: true
            },
            {
                name: prefix + "submit",
                value: ""
            },
        ]))

    }, [])

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: prefix + "submit",
                loading
            })
        )
    }

    /**
     * Update Alert
     */
    const updateAlert = (severity: FormAlertTypes, message: string) =>  dispatch(
        createOrUpdateElementData({
            name: prefix + "alert",
            value: message,
            severity: severity
        })
    )

    /**
     * Get single form element data
     */
    const getSingleFormElementData = (name: string) => returnSingleFormElementData(prefix + name).value

    /**
     * Submit form
     */
    const submitForm = () => {
        updateButtonLoadingStatus(true)

        let data: AxiosDefaultDataObject = {
            labour_id: getSingleFormElementData("labour_id"),
            project_id: props.projectId,
            location_id: getSingleFormElementData("location_id"),
            shift: getSingleFormElementData("shift"),
            break_time: getSingleFormElementData("break_time"),
            break_type: getSingleFormElementData("break_type"),
            update: props.update,
        }

        if (props.labourAssignedId){
            data.id = props.labourAssignedId
        }

        const formValidator = new FormValidator(labourAssignedToProjectApp.requiredFields)

        if (formValidator.isValid().valid){

            labourAssignedToProjectApp.createNewInstance(data)
                .then(response => {
                        
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            props.refreshResults()
                            props.closeModal()
                            break

                    }

                    updateButtonLoadingStatus(false)

                })
                .catch(error => {
                    updateButtonLoadingStatus(false)
                    
                    const response = error.response
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                    labourAssignedToProjectApp.logResults(response)

                    switch(response.status){

                        default:
                           updateAlert("error", message)
                    }
                
                })

        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }

    }

    /**
     * Update element data
     */
    const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: prefix + name, value }))

    /**
     * Load labour assigned details
     */
    const loadLabourAssignedDetails = () => {
        if (props.update && props.labourAssignedId){
            labourAssignedToProjectApp.getSingleInstanceDetails(props.labourAssignedId)
                .then(response => {
                            
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            setLoading(false)
                            const labour: LabourInterface = data.labour
                           
                            if (labour){
                                updateElementData("labour_id", labour.id)
                                updateElementData("location_id", data.location_id)
                                updateElementData("shift", data.shift)
                                updateElementData("break_time", data.break_time)
                                updateElementData("break_type", data.break_type)
                            }

                            break

                    }


                })
                .catch(error => {
                    setLoading(false)
                    
                    const response = error.response
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                    labourAssignedToProjectApp.logResults(response)

                    switch(response.status){

                        default:
                            updateAlert("error", message)
                    }
                
                })

        }
    }

    /**
     * Generate preloader
     */
    const generatePreloader = () => {
        let className: string = "preloader-of-labour-assigned-form-container"
        if (loading){
            className += " active"
        }

        return(
            <div className={className}>
                <div className="content-of-preloader-of-labour-assigned-form-container">
                    <CircularProgress />
                </div>
            </div>
        )
    }

    /**
     * Load labour assigned
     */
    useEffect(() => {
        loadLabourAssignedDetails()
    }, [props.labourAssignedId])

    return(
        <div className="labour-assigned-form-container">
            <div className="content-of-labour-assigned-form-container">
                {generatePreloader()}
                <GenerateForm name={formName} handleSubmitForm={submitForm} />
            </div>
        </div>
    )
}