import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CheckInApp, { ReportInterface } from "./CheckInApp"
import { addManyElementsData, returnSingleFormElementData } from '../../FormElements/formsDataSlice'
import LabourApp from '../labour/LabourApp'
import LocationsApp from '../locations/LocationsApp'
import ProjectsApp from '../projects/ProjectsApp'
import GenerateForm from '../../FormElements/GenerateForm'
import { DataDisplayerSingleSearchParam } from '../../DataDisplayer/Interfaces'
import { addNewForm } from '../../FormElements/formsSlice'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import Report from './Report'
import DateAndTime from '../../DateAndTime/DateAndTime'
import { Button } from '@mui/material'
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { LoadingButton } from '@mui/lab'
import TeamsApp from '../teams/TeamsApp'


interface Props {
    projectId?: number,
    locationId?: number,
    labourId?: number,
    teamId?: number,
    startDate?: string,
    finishDate?: string
}

export default function GenerateReport(props: Props) {

    const dispatch = useDispatch()

    const { projectId, locationId, labourId, teamId, startDate, finishDate } = props

    const checkInApp = new CheckInApp()
    const locationsApp = new LocationsApp()
    const dateAndTime = new DateAndTime()
    const teamsApp = new TeamsApp()

    const [generatingPdfStatus, setGeneratingPdfStatus] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)
    const [report, setReport] = useState<ReportInterface>({
        labour: [],
        project: {
            id: 0,
            title: "",
            description: "",
            location_ids: [],
            is_active: false,
            user_id: 0,
            date_created: "",
            last_updated: "",
            thumbnail: "",
            user_name: ""
        },
        is_all_locations: false,
        labour_name: "",
        start_date: "",
        finish_date: "",
        location_name: "",
        total_amount: 0,
        total_hours: 0,
        report_name: ""
    })  

    /**
     * Message
     */
    interface IMessage  {
        type: MessageBoxTypes,
        title: string,
        message: string,
        display: boolean
    }
    const [message, setMessage] = useState<IMessage>({
        type: "info",
        title: "",
        message: "",
        display: false
    })

    /**
     * Search Form
     */
    const prefix: string = "generate_report_form_"
    const formName: string = "generate_report_form"

    /**
     * Load locations
     */
    const customLoadLocationsFunc = (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam> ) => locationsApp.locationsList(projectId ? projectId : false, page, search, disablePagination, searchParams)

    /**
     * Get single form element data
     */
    const getSingleFormElementData = (name: string) => returnSingleFormElementData(prefix + name).value

    /**
     * Create form
     */
    useEffect(() => {
        /**
         * Add new form
         */
        dispatch(addNewForm({
            name: formName,
            elements: [
                {
                    type: "DynamicSelect",
                    xs: 4,
                    id: prefix + "labour_id",
                    label: "Labour",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "full_name",
                    appClass: new LabourApp(),
                    addNoneItem: true,
                    formName: formName,
                },
                {
                    type: "DynamicSelect",
                    xs: 4,
                    id: prefix + "team_id",
                    label: "Team",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new TeamsApp(),
                    addNoneItem: true,
                    formName: formName,
                },
                {
                    type: "DynamicSelect",
                    xs: 4,
                    id: prefix + "location_id",
                    label: "Location",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new LocationsApp(),
                    addNoneItem: true,
                    formName: formName,
                    customLoadFunc: customLoadLocationsFunc
                },
                {
                    type: "DynamicSelect",
                    xs: 3,
                    id: prefix + "project_id",
                    label: "Project",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new ProjectsApp(),
                    addNoneItem: false,
                    formName: formName,
                },
                {
                    type: "DateAndTimeField",
                    fieldType: "text",
                    xs: 3,
                    id: prefix + "start_date",
                    label: "From",
                    fullWidth: true
                },
                {
                    type: "DateAndTimeField",
                    fieldType: "text",
                    xs: 3,
                    id: prefix + "finish_date",
                    label: "To",
                    fullWidth: true
                },
                {
                     type: "Button",
                     xs: 3,
                     id: prefix + "submit",
                     label: "Display",
                     fullWidth: true,
                     onClick: () => {},
                     isSubmit: true
                 },
                 {
                    type: "Alert",
                    xs: 12,
                    label: "",
                    id: prefix + "alert"
                },
            ]
        }))

         /**
         * Update form data
         */
         dispatch(addManyElementsData([
            {
                name: prefix + "labour_id",
                value: labourId ? labourId : ""
            },
            {
                name: prefix + "location_id",
                value: locationId ? locationId : ""
            },
            {
                name: prefix + "project_id",
                value: projectId ? projectId : ""
            },
            {
                name: prefix + "team_id",
                value: teamId ? teamId : ""
            },
            {
                name: prefix + "start_date",
                value: startDate ? startDate : ""
            },
            {
                name: prefix + "finish_date",
                value: finishDate ? finishDate : ""
            },
            {
                name: prefix + "alert",
                value: "Test",
                severity: "info",
                hidden: true
            },
            {
                name: prefix + "submit",
                value: ""
            },
        ]))
}, [])

    /**
     * Generate report
     */
    const generateReport = () => {
        setLoading(true)

        const getProjectId = getSingleFormElementData("project_id")
        const getTeamId = getSingleFormElementData("team_id")
        const getLocationId = getSingleFormElementData("location_id")
        const getLabourId = getSingleFormElementData("labour_id")
        const getStartDate = getSingleFormElementData("start_date")
        const getFinishDate = getSingleFormElementData("finish_date")

        let data = {
            project_id: getProjectId ? getProjectId : projectId,
            location_id: getLocationId ? getLocationId : locationId,
            labour_id: getLabourId ? getLabourId : labourId,
            team_id: getTeamId ? getTeamId : teamId,
            start_date: getStartDate ? dateAndTime.dateJsFormatDateAndTime(getStartDate) : startDate,
            finish_date: getFinishDate ?  dateAndTime.dateJsFormatDateAndTime(getFinishDate) : finishDate
        }

        checkInApp.generateReport(data.project_id, data.location_id, data.labour_id, data.start_date, data.finish_date, data.team_id)
            .then(response => {

                const { status } = response
                const data: ReportInterface = response.data

                switch(status){

                    case 200:
                        if (data){
                            setReport(data)
                            setMessage({
                                display: false,
                                title: "Error",
                                message: "",
                                type: "info"
                            })
                        }
                        break

                }

                setLoading(false)

            })
            .catch(error => {
                setLoading(false)
                
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                checkInApp.logResults(response)

                switch(response.status){

                    case 400:
                        setMessage({
                            display: true,
                            title: "Info",
                            message: message,
                            type: "info"
                        })
                        break

                    default:
                        setMessage({
                            display: true,
                            title: "Error",
                            message: message,
                            type: "error"
                        })
                }
            })


    }

    /**
     * Handle search submit
     */
    const handleSubmit = () => {
        generateReport()
    }

    /**
     * generate report
     */
    useEffect(() => {
        generateReport()
    }, [])

    /**
     * Generate pdf
     */
    const generatePDF = () => {
        // const pdfFile = new JsPDF('portrait','pt','a4');
        setGeneratingPdfStatus(true)
        const elem = document.getElementById("report")
        
        if (elem){
            // html2canvas(elem, {
            //     useCORS: true,
            //     allowTaint : true,
            // }).then(canvas => {
                // const data = canvas.toDataURL('image/png');

                // const pdf = new JsPDF();
                // const imgProperties = pdf.getImageProperties(data);
                //  const pdfWidth = pdf.internal.pageSize.getWidth();
                // // const pdfHeight = ((imgProperties.height + 100) * pdfWidth) / imgProperties.width
                // const pdfHeight = imgProperties.height
            
                // pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight, "", "MEDIUM");
                // pdf.save(`${report.report_name}.pdf`);

                // setGeneratingPdfStatus(false)
            // });
            html2canvas(elem, {
              }).then((canvas) => {
        
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190;
                const pageHeight = 290;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                const doc = new JsPDF({
                    orientation: "p",
                    unit: "mm",
                    compress: true
                });
                let position = 0;
                doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25, "", "MEDIUM");
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25,  "", "MEDIUM");
                    heightLeft -= pageHeight;
                }
                doc.save(`${report.report_name}.pdf`);

                setGeneratingPdfStatus(false)
              });
        }else{
            setGeneratingPdfStatus(false)
        }
    }

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <div className="title-box-of-admin-panel-single-section-container">
                    <div className="content-of-title-box-of-admin-panel-single-section-container">
                        <h3>Generate Report</h3>
                    </div>
                </div>

                <div className="check-in-list-search-form-container">
                    <div className="content-of-check-in-list-search-form-container">
                        <GenerateForm name={formName} handleSubmitForm={handleSubmit} />
                    </div>
                </div>

                {message.display ? (
                     <div className="generate-report-message-container">
                        <div className="content-of-generate-report-message-container">
                            <GenerateMessageBox type={message.type} title={message.title} message={message.message} />
                        </div>
                    </div>
                ) : (
                    <div className="display-report-container">
                        <div className="content-of-display-report-container">

                            <div className="labour-check-in-report-hidden">
                                <Report {...report} loading={loading} customId="report" />
                            </div>

                            <Report {...report} loading={loading} customId="display-report" />
                        </div>
                    </div>
                )}

                <div className="save-report-action-button-container">
                    <div className="content-of-save-report-action-button-container">
                        <LoadingButton loading={generatingPdfStatus} disabled={report.report_name ? false : true} variant="contained" onClick={() => generatePDF()}>
                            Save as PDF
                        </LoadingButton>
                    </div>
                </div>

            </div>
        </div>
    )
}
