import { AxiosDefaultDataObject } from "../../Api/Interfaces";
import BaseApp from "../BaseApp";

import ViewSingleLabourPage from "./ViewSingleLabourPage"

/**
 * labour Interface 
 */
export interface LabourInterface {
    id: number,
    unique_id: string,
    passport_number: string,
    passport_img: string,
    full_name: string,
    designation_id: number,
    rate: number,
    currency: string,
    phone: string,
    is_active: boolean,
    user_id: number,
    date_created: string,
    last_updated: string,
    photo: string,
    designation_name: string,
    user_name: string,
    team_name: string
}

export interface ItemDetailsSingleBoxProps {
    title: string,
    value: string,
    additionalClass?: string,
    multiLine?: boolean,
    htmlCode?: boolean,
    xs?: number,
    useNewDesign?: boolean,
    loading?: boolean,
    customContent?: () => void
}

export const emptyLabourInterface: LabourInterface = {
    id: 0,
    unique_id: "",
    passport_number: "",
    passport_img: "",
    full_name: "",
    designation_id: 0,
    rate: 0,
    currency: "",
    phone: "",
    is_active: false,
    user_id: 0,
    date_created: "",
    last_updated: "",
    photo: "",
    designation_name: "",
    user_name: "",
    team_name: ""
}

/**
 * Companies App
 */
export default class LaboutApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "labour"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "labour"

    /**
     * Required fields
     */
    requiredFields = [
        "passport_number",
        "passport_img",
        "full_name",
        "designation_id",
        "rate",
        "currency",
        "phone",
        "photo"
    ]

    /**
     * Detect face
     */
    detectFace = (data: AxiosDefaultDataObject) => this.sendRequest({
        method: "POST",
        data,
        url: this.appBaseEndPointPath + "/face-recognition",
        withCredentials: true
    })

    /**
     * View Page
     */
    viewAdminContentPage = () => {
        return <ViewSingleLabourPage />
    }

}