import Api from "../Api"
import axios from "axios"

/**
 * Authentication
 */
export default class Authentication{
    email: string
    password: string
    baseApiUrl: string
    otp: number | null = null

    constructor(email: string, password: string, otp?: number){
        this.email = email
        this.password = password
        this.baseApiUrl = Api.returnBaseUrl()

        if (otp){
            this.otp = otp
        }
    }

    /**
     * Authenticate user
     */
    authenticateUser = async() => {
        const response = await axios({
            baseURL: this.baseApiUrl,
            url: "users/login",
            method: "POST",
            data: {
                email: this.email,
                password: this.password,
                otp: this.otp
            },
            withCredentials: true,
        })
        return response
    }

    /**
     * Logout User
     */
    logoutUser = async() => {
        const response = await axios({
            baseURL: this.baseApiUrl,
            url: "users/logout",
            method: "POST",
            data: {
                
            },
            withCredentials: true,
        })
        return response
    }

    /**
     * Register Two Factor Auth
     */
    registerTwoFactorAuth = async() => {
        const response = await axios({
            baseURL: this.baseApiUrl,
            url: "users/two-factor-auth/register",
            method: "POST",
            data: {
                
            },
            withCredentials: true,
        })
        return response
    }

    /**
     * Verify OTP Code
     */
    verifyOTPCode = async(otp: number) => {
        const response = await axios({
            baseURL: this.baseApiUrl,
            url: "users/two-factor-auth/verify",
            method: "POST",
            data: {
                otp
            },
            withCredentials: true,
        })
        return response
    }

    /**
     * Block User account
     */
    blockUserAccount = async(email: string, password: string, otp: string) => {
        const response = await axios({
            baseURL: this.baseApiUrl,
            url: "users/block-account",
            method: "POST",
            data: {
                email,
                password,
                otp
            },
            withCredentials: true,
        })
        return response
    }

}