import Api from "../Api/Api";
import ApiEndpoint from "../Api/ApiEndpoint";
import { AxiosDefaultDataObject } from "../Api/Interfaces";

export interface UploadImageInterface {
    uploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const returnImageUrl = (img: string) => {

    const appUrl = Api.returnBaseUrl()
    img.replace("http://127.0.0.1:8000/", appUrl)
    img.replace("http://192.168.1.125:8000/", appUrl)

    return img
}

/**
 * Upload image
 */
// export default class UploadImage extends ApiEndpoint implements UploadImageInterface{

//     appName: string = "uploader"
//     appBaseEndPointPath: string = "uploader"

//     /**
//      * Upload image
//      */
//     uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        
//         if (event.target.files && event.target.files[0] !== undefined){
//             const image = event.target.files[0]

//             let data = new FormData()
//             data.append("image", image)

//             return this.sendRequest({
//                 method: "POST",
//                 url: this.appBaseEndPointPath + "/image",
//                 data,
//                 withCredentials: true
//             })

//         }


//     }

//     /**
//      * Upload id card
//      */
//     uploadIdCard = (event: React.ChangeEvent<HTMLInputElement>) => {
        
//         if (event.target.files && event.target.files[0] !== undefined){
//             const image = event.target.files[0]

//             let data = new FormData()
//             data.append("image", image)

//             return this.sendRequest({
//                 method: "POST",
//                 url: this.baseApiUrl + "labour/upload-id-card",
//                 data,
//                 withCredentials: true
//             })

//         }


//     }

//     /**
//      * Upload id card
//      */
//     uploadFace = (event: React.ChangeEvent<HTMLInputElement>) => {
        
//         if (event.target.files && event.target.files[0] !== undefined){
//             const image = event.target.files[0]

//             let data = new FormData()
//             data.append("image", image)

//             return this.sendRequest({
//                 method: "POST",
//                 url: this.baseApiUrl + "labour/upload-face",
//                 data,
//                 withCredentials: true
//             })

//         }


//     }

//     /**
//      * Upload image
//      */
//     uploadPdf = (event: React.ChangeEvent<HTMLInputElement>) => {
        
//         if (event.target.files && event.target.files[0] !== undefined){
//             const image = event.target.files[0]

//             let data = new FormData()
//             data.append("file", image)

//             return this.sendRequest({
//                 method: "POST",
//                 url: this.appBaseEndPointPath + "/pdf",
//                 data,
//                 withCredentials: true
//             })

//         }


//     }

//     /**
//      * Data to blob
//      */
//     dataURIToBlob(dataURI: string) {
//         const splitDataURI = dataURI.split(',')
//         const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
//         const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

//         const ia = new Uint8Array(byteString.length)
//         for (let i = 0; i < byteString.length; i++)
//             ia[i] = byteString.charCodeAt(i)

//         return new Blob([ia], { type: mimeString })
//       }

// }
export default class UploadImage extends ApiEndpoint implements UploadImageInterface{

    appName: string = "uploader"
    appBaseEndPointPath: string = "uploader"

    /**
     * Upload image
     */
    uploadImage = (data: AxiosDefaultDataObject) => this.sendRequest({
        method: "POST",
        url: this.appBaseEndPointPath + "/image",
        data,
        withCredentials: true
    })

    /**
     * Upload id card
     */
    uploadIdCard = (data: AxiosDefaultDataObject) => this.sendRequest({
        method: "POST",
        url: this.baseApiUrl + "labour/upload-id-card",
        data,
        withCredentials: true
    })

    /**
     * Upload id card
     */
    uploadFace = (data: AxiosDefaultDataObject) => this.sendRequest({
        method: "POST",
        url: this.baseApiUrl + "labour/upload-face",
        data,
        withCredentials: true
    })

    /**
     * Upload image
     */
    uploadPdf = (data: AxiosDefaultDataObject) => this.sendRequest({
        method: "POST",
        url: this.appBaseEndPointPath + "/pdf",
        data,
        withCredentials: true
    })


    /**
     * Data to blob
     */
    dataURIToBlob(dataURI: string) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }

}