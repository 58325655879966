import React from 'react'
import { ReportInterface } from './CheckInApp'
import DateAndTime from '../../DateAndTime/DateAndTime'

export interface ReportInfoBoxInterface {
    title: string,
    value: any,
    loading?: boolean
}

interface Props extends ReportInfoBoxInterface {
}

export default function ReportInfoBox(props: Props) {

    const { title, value } = props


    return(
        <div className="single-info-box-of-check-in-report">
            <div className="content-of-single-info-box-of-check-in-report">

                <div className="left-of-single-info-box-of-check-in-report">
                    <div className="content-of-left-of-single-info-box-of-check-in-report">
                        <h3>{title}</h3>
                    </div>
                </div>

                <div className="right-of-single-info-box-of-check-in-report">
                    <div className="content-of-right-of-single-info-box-of-check-in-report">
                        <h4>{value}</h4>
                    </div>
                </div>

            </div>
        </div>
    )
}