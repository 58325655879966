import moment from "moment"
import dayjs from 'dayjs';

/**
 * Date and time
 */
export default class DateAndTime{

    timezone: string = "Asia/Bahrain"
    locale: string = "en-US"
    dateFormat: string = "MMMM Do, YYYY"
    timeFormat: string = "h:mm a"
    serverDateAndTimeFormat: string = "YYYY-MM-DD HH:mm:ss"
    serverTimeFormat: string = "HH:mm"
    serverDateFormat: string = "YYYY-MM-DD"
    dateAndTimeFormat: string = this.dateFormat + ", " + this.timeFormat

    /**
     * Get Current date
     */
    getCurrentDate = () => {
        return new Date().toLocaleString(this.locale, {
            timeZone: this.timezone,
        })
    }

    /**
     * Format date 
     */
    formatDate = (date: string) => moment(date).format(this.dateFormat)

    /**
     * Format date and time
     */
    formatDateAndTime = (date: string) => moment(date).format(this.dateAndTimeFormat)

    /**
     * Format time
     */
    formatTime = (date: string) => moment(date).format(this.timeFormat)

    /**
     * Format to server standard
     */
    formatToServerStandart = (date: string) => moment(date).format(this.serverDateAndTimeFormat)

    /**
     * Format to server standard
     */
    formatToServerDateOnlyStandart = (date: string) => moment(date).format(this.serverDateFormat)

    /**
     * Format to server standard
     */
    formatToServerTimeOnlyStandart = (date: string) => moment(date).format(this.serverTimeFormat)

    /**
     * Day js format date
     */
    dateJsFormatDate = (date: string) => dayjs(date).format(this.serverDateFormat)

    /**
     * Day js format date
     */
    dateJsFormatDateAndTime = (date: string) => dayjs(date).format(this.serverDateAndTimeFormat)

    /**
     * Day js format time
     */
    dateJsFormatTime = (date: string) => dayjs(date).format(this.serverTimeFormat)

}