import BaseApp from "../BaseApp";
import SingleRegistrationCounterPage from "./SingleRegistrationCounterPage"

/**
 * Registration Counter Interface
 */
export interface RegistrationCounterInterface {
    id: number,
    title: string,
    description: string,
    thumbnail: string,
    project_id: number,
    location_id: number,
    token: string,
    token_expires: string,
    is_public: boolean,
    is_active: boolean,
    user_id: number,
    date_created: string,
    last_updated: string,
    project_name: string,
    location_name: string,
    user_name: string
}

/**
 * Designtaions App
 */
export default class RegistrationCountersApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "counters"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "registration-counters"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "project_id",
        "location_id"
    ]

    /**
     * Set token
     */
    setToken = async(token: string) => this.sendRequest({
        method: "POST",
        data: {
            token
        },
        url: this.appBaseEndPointPath + "/tokens/set-up",
        withCredentials: true
    })

    /**
     * Verify token
     */
    verifyToken = async() => this.sendRequest({
        method: "POST",
        data: {
            
        },
        url: this.appBaseEndPointPath + "/tokens/verify",
        withCredentials: true
    })

    /**
     * Refesh token
     */
    refreshToken = async(token: string| boolean = false) => this.sendRequest({
        method: "POST",
        data: {
            token
        },
        url: this.appBaseEndPointPath + "/tokens/refresh",
        withCredentials: true
    })

    /**
     * New record
     */
    createNewRecord = async(registration_type: string, labour_unique_id: string, photo: string) => this.sendRequest({
        method: "POST",
        data: {
            registration_type,
            labour_unique_id,
            photo
        },
        url: this.appBaseEndPointPath + "/new-record",
        withCredentials: true
    })

    /**
     * Validate expiry date
     */
    validateExpiryDate = (tokenExpiryDate: string) => {
        const currentDate = new Date()
        const expiryDate = new Date(tokenExpiryDate)
        expiryDate.setMinutes(expiryDate.getMinutes() - 1)

        if (currentDate >= expiryDate){
            console.log("return false")
            return false
        }

        return true
        
    }

    /**
     * View Page
     */
    viewAdminContentPage = () => {
        return <SingleRegistrationCounterPage />
    }

}