import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LabourAssignedForm from './LabourAssignedForm';

interface Props {
    open: boolean,
    update?: boolean,
    projectId: number,
    handleClose: () => void,
    refreshResults: () => void,
    labourAssignedId?: number
}

export default function CreateLabourAssignedModal(props: Props) {

    const { open, handleClose, projectId, update } = props

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
      >
            <DialogTitle id="alert-dialog-title">
                {update ? "Update Assigned Labour" : "Assign Labour"}
            </DialogTitle>

            <DialogContent>
                <div style={{ marginTop: 5 }}>
                    <LabourAssignedForm projectId={projectId} update={update} closeModal={handleClose} refreshResults={props.refreshResults} labourAssignedId={props.labourAssignedId} />
                </div>
            </DialogContent>

            {/* <DialogActions>

                <Button onClick={handleClose}>Disagree</Button>
            </DialogActions> */}

        </Dialog>
    )
}