import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProjectsApp, { ProjetcsInterface } from './ProjectsApp'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import ProjectDetails from "./ProjectDetails"
import LocationsList from "../locations/LocationsList"
import LabourAssignedList from "../labour/LabourAssignedList"
import CheckInList from '../check_in/CheckInList'
import GenerateReport from "../check_in/GenerateReport"

export default function ViewSingleProjectPage() {

    const params = useParams()
    const projectId: number = params.id ? Number(params.id) : 0

    const projectsApp = new ProjectsApp()

    const [loading, setLoading] = useState<boolean>(false)
    const [project, setProject] = useState<ProjetcsInterface>({
        id: 0,
        title: "",
        description: "",
        location_ids: [],
        is_active: false,
        user_id: 0,
        date_created: "",
        last_updated: "",
        thumbnail: "",
        user_name: ""
    })

    /**
     * Not Found
     */
    const [errorMessage, setErrorMessage] = useState<string>("")

    /**
     * Load labour details
     */
    const loadProjectDetails = () => {
        setLoading(true)

        projectsApp.getSingleInstanceDetails(projectId)
            .then(response => {
                            
                const { status, statusText, data } = response

                switch(status){

                    case 200:
                        const project: ProjetcsInterface = data
                        setProject(project)
                        break

                }

                setLoading(false)
                setErrorMessage("")

            })
            .catch(error => {
                setLoading(false)
                
                projectsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)
            })

    }

    /**
     * Load labour details
     */
    useEffect(() => {
        loadProjectDetails()
    }, [])

    /**
     * Labour Assigned box
     */
    const renderLabourAssignedBox = () => {
        return (
            <div className="admin-panel-single-section-container">
                <div className="content-of-admin-panel-single-section-container">
                    <LabourAssignedList projectId={project.id} locationId={false} />
                </div>
            </div>
        )
    }

    /**
     * Render not found message
     */
    if (errorMessage){
        return <GenerateMessageBox type="error" title="Nothing was found.." message={errorMessage} />
    }

    return(
        <div className="view-single-project-page-container">
            <div className="content-of-view-single-project-page-container">

                <ProjectDetails {...project} loading={loading} />
                {!loading && <LocationsList projectId={project.id} />}
                {!loading && renderLabourAssignedBox()}
                {!loading && <CheckInList projectId={project.id} />}
                {!loading && <GenerateReport projectId={project.id} /> }

            </div>
        </div>
    )
}