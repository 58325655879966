import React, { useEffect, useState } from 'react'
import LocationsApp, { LocationInterface } from './LocationsApp'
import { DataDisplayerItemButton, DataDisplayerItemField } from '../../DataDisplayer/Interfaces'
import DataSingleDisplayerItem from '../../DataDisplayer/DataSingleDisplayerItem'
import { IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'

interface Props {
    id: number,
    deleteLocation: (id: number) => void
}

export default function SingleLocationField(props: Props) {

    const { id, deleteLocation } = props

    const locationsApp = new LocationsApp()

    const [loading, setLoading] = useState<boolean>(true)
    const [location, setLocation] = useState<LocationInterface>({
        id: 0,
        title: "",
        address: "",
        google_maps: "",
        is_active: false,
        user_id: 0,
        date_created: "",
        last_updated: "",
    })

    /**
     * Load location details function
     */
    const loadDetails = () => {
        setLoading(true)

        locationsApp.getSingleInstanceDetails(id)
            .then(response => {
                const { data } = response
                setLocation(data)

                setLoading(false)
            })
            .catch(error => {
                const { status, statusText, data } = error.response
                const message = data.message ? data.message : statusText

                alert(message)
                setLoading(false)
            })

    }

    /**
     * Load location details
     */
    useEffect(() => {
        loadDetails()
    }, [id])

    const fields: Array<DataDisplayerItemField> = [
        {
            type: "text",
            label: "Location",
            value: location.title,
            maxWidth: 300,
            minWidth: 300
        },
        {
            type: "text",
            label: "Address",
            value: location.address,
            maxWidth: 350,
            minWidth: 350
        },
    ]
    const buttons: Array<DataDisplayerItemButton> = [
        {
            type: "custom",
            label: "delete",
            customContent: (details) => {
                return <IconButton onClick={() => deleteLocation(id)}><GenerateIcon icon="delete" /></IconButton>
            }
        }
    ]

    return <DataSingleDisplayerItem sectionName="check-in" appClass={locationsApp} id={id} fields={fields} buttons={buttons} />
}