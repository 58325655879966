import React from 'react'
import { ProjetcsInterface } from './ProjectsApp'
import ItemDetailsSingleBox, { ItemDetailsSingleBoxProps } from '../labour/ItemDetailsSingleBox'
import DateAndTime from '../../DateAndTime/DateAndTime'
import { Avatar, Button, Grid, Skeleton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import NoThumb from "../../../assets/svg/no-thumb.svg"

interface Props extends ProjetcsInterface {
    loading?: boolean
}

export default function ProjectDetails(props: Props) {

    const { id, title, date_created, last_updated, user_name, description, thumbnail } = props

    /**
     * Items
     */
    const itemDetailsArray: Array<ItemDetailsSingleBoxProps> = [
        {
            title: "Title:",
            value: title,
            xs: 12
        },
        {
            title: "Description:",
            value: description ? description : "-",
            xs: 12
        },
        {
            title: "Created by:",
            value: user_name,
            xs: 12
        },
        {
            title: "Date created:",
            value: date_created ? new DateAndTime().formatDateAndTime(date_created) : "-",
            xs: 6
        },
        {
            title: "Last Updated:",
            value: last_updated ? new DateAndTime().formatDateAndTime(last_updated) : "-",
            xs: 6
        },
    ]

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">

                <div className="single-item-details-page-boxes-container">
                    <div className="content-of-single-item-details-page-boxes-container">

                        <Grid container spacing={2}>
                            
                            <Grid item xs={2}>
                                <div className="thumb-box-of-single-item-details-page-boxes-container">
                                    <div className="content-of-thumb-box-of-single-item-details-page-boxes-container">
                                        {props.loading ? <Skeleton variant="rectangular" width={"100%"} height={350} /> : <Avatar src={thumbnail ? thumbnail : NoThumb} sx={{ width: 150, height: 150 }} />}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={10}>
                                <div className="field-boxes-of-single-item-details-page-boxes-container">
                                    <div className="content-of-field-boxes-of-single-item-details-page-boxes-container">
                                        <Grid container spacing={2}>
                                            {itemDetailsArray.map((singleItemDetailsBox: ItemDetailsSingleBoxProps, index: number) => <ItemDetailsSingleBox loading={props.loading} useNewDesign={true} {...singleItemDetailsBox} key={index} />)}
                                        </Grid>
                                    </div>
                                </div>

                                <div className="item-details-action-buttons-container">
                                    <div className="content-of-item-details-action-buttons-container">

                                        {props.loading && (
                                            <div style={{ display: "flex" }}>
                                                <Skeleton width={100} height={50} variant="rectangular" style={{ marginRight: 5 }} />
                                            </div>
                                        )}


                                        {!props.loading && (
                                            <Button  startIcon={<GenerateIcon icon="edit" />} href={"/panel/projects/edit/" + id + "?back=/panel/projects/view/" + id}>
                                                Edit
                                            </Button>
                                        )}

                                    </div>
                                </div>
                            </Grid>

                        </Grid>

                    </div>
                </div>

            </div>
        </div>
    )
}