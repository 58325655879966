import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckInForm from './CheckInForm';

interface Props {
    open: boolean,
    update?: boolean,
    checkInId?: number,
    handleClose: () => void,
    refreshResults: () => void,
    projectId?: number,
    locationId?: number,
    labourId?: number
}

export default function CheckInModal(props: Props) {

    const { open, handleClose, checkInId, update, projectId, labourId ,locationId } = props

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
      >
            <DialogTitle id="alert-dialog-title">
                {update ? "Update Record" : "Create new record"}
            </DialogTitle>

            <DialogContent>
                <div style={{ marginTop: 5 }}>
                    <CheckInForm checkInId={checkInId} update={update} closeModal={handleClose} refreshResults={props.refreshResults} projectId={projectId} labourId={labourId} locationId={locationId} />
                </div>
            </DialogContent>


        </Dialog>
    )
}