import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userSlice from '../components/Api/User/userSlice';
import dataDisplayerItemsSlice from '../components/DataDisplayer/dataDisplayerItemsSlice';
import formsDataSlice from '../components/FormElements/formsDataSlice';
import formsSlice from '../components/FormElements/formsSlice';
import pagePreloaderSlice from '../components/PagePreloader/pagePreloaderSlice';
import errorPageSlice from '../components/Pages/Error/errorPageSlice';
import pagesSlice from '../components/Router/pagesSlice';
import authSlice from "../components/AdminPanel/authSlice"

export const store = configureStore({
  reducer: {
    pages: pagesSlice,
    forms: formsSlice,
    formsData: formsDataSlice,
    pagePreloader: pagePreloaderSlice,
    user: userSlice,
    errorPage: errorPageSlice,
    dataDisplayerItems: dataDisplayerItemsSlice,
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});
 
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
